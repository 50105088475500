import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import apiurl from './apiurl';
import axios from 'axios';





export default class Info extends React.Component {
    constructor() {
      super();    
      this.state = {
      errors: null, 
      wersja_front: "v2019.3.1.0.1125",
      wersja_api: "",
      };
      this.wersja();
    }
  
  wersja = () => {
  axios
    .get(apiurl+"api/info")
    .then(response => 
      this.setState({ wersja_api : response.data })
    )
    .catch(error => console.log(error));
  }


    
  render() {
  const {wersja_api, wersja_front} = this.state;
  return (
  <React.Fragment>
  <div class="container-fluid">
  <center>
  <p>eWizja</p>
  <p>{wersja_front}</p>
  <p>API: {wersja_api}</p>
  <p>INFO-SYSTEM Roman i Tadeusz Groszek sp.j. </p>
  <p>05-120 Legionowo, ul. Piłsudskiego 31 lok. 240 </p>
  <p>tel: 22 784-49-44, 22 732-01-49, 22 732-01-50 </p>
  <p>fax: 784 49 88 </p>
  <p>e-mail: <a href="mailto:poczta@groszek.pl">poczta@groszek.pl</a> </p>
  <p><a href="http://www.groszek.pl">www.groszek.pl</a> </p>

      
  </center>   
  </div>
  </React.Fragment>
  );
  }
  }
