import React from 'react';
import ReactDOM from 'react-dom';
import Dodaj_wydzial from './Dodaj_wydzial';
import Edytuj_wydzial from './Edytuj_wydzial';
import NavBar from './NavBar';
import ChangePswd from './ChangePswd';
import axios from 'axios';
import swal from 'sweetalert';
import './index.css';
import apiurl from './apiurl';


export default class Wydzialy extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
    token: props.token ,
    username: props.username ,
    kodoper: props.kodoper,
    nazwastrony: 'Wydzialy',
    rok: props.rok,
    data1: [],
    };
    this.Wydzialy();
  }

  componentWillMount(){
    ReactDOM.render(<NavBar token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
  }

  componentWillUpdate(nextProps){
    if(this.state.rok !== nextProps.rok ){
    this.setState({ rok : nextProps.rok}, () => { this.Wydzialy() })
    }
  }

  Wydzialy = () => {
    axios
      .get(apiurl+"api/WydzialyStruktura?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }})
      .then(response => {
        this.setState({ data1 : response.data });
      }
      )
      .catch(error => swal("Błąd!"));
  }

zmiana = (s) => { this.setState({ [s.target.name]: s.target.value }, () => { ReactDOM.render(<Edytuj_wydzial token = {this.state.token}  idWydzialu={this.state.id} rok={this.state.rok}/>, document.getElementById("root")) }) }

render() {
const { data1, rok } = this.state
return (
<React.Fragment >
<div class="container-fluid">
<h1>Wydziały</h1>
<hr />
<button onClick={() => ReactDOM.render(<Dodaj_wydzial apiurl={this.props.apiurl} token = {this.state.token} username = {this.state.username} rok={this.state.rok} />, document.getElementById("root"))} type="button" class="btn btn-light">Dodaj wydział</button>
<br></br>
<br></br>

<div class="card">

<div class="row">
<ul>
  {data1.map(user => {
    const { id, nazwa, kod, podrzedne } = user;
    return (
      <li key={id}>
        {nazwa} - <a className="wydzialylink" onClick={() => ReactDOM.render(<Edytuj_wydzial apiurl={this.props.apiurl} token = {this.state.token}  idWydzialu={id} rok={this.state.rok} nazwawydzial={nazwa} kodwydzial={kod} username = {this.state.username} />, document.getElementById("root"))} >Edytuj</a>
        <ul>
          {podrzedne.map(elem => {    
            const { id, nazwa, podrzedne } = elem;
            return (
              <li key={id}>
                {nazwa} - <a className="wydzialylink" onClick={() => ReactDOM.render(<Edytuj_wydzial apiurl={this.props.apiurl} token = {this.state.token}  idWydzialu={id} rok={this.state.rok} nazwawydzial={nazwa} kodwydzial={kod} username = {this.state.username} />, document.getElementById("root"))} >Edytuj</a>
                <ul>
                  {podrzedne.map(elem2 => {    
                    const { id, nazwa, podrzedne } = elem2;
                    return (
                      <li key={id}>
                        {nazwa} - <a className="wydzialylink" onClick={() => ReactDOM.render(<Edytuj_wydzial apiurl={this.props.apiurl} token = {this.state.token}  idWydzialu={id} rok={this.state.rok} nazwawydzial={nazwa} kodwydzial={kod} username = {this.state.username} />, document.getElementById("root"))} >Edytuj</a>
                        <ul>
                          {podrzedne.map(elem3 => {    
                            const { id, nazwa, podrzedne } = elem3;
                            return (
                              <li key={id}>
                                {nazwa} - <a className="wydzialylink" onClick={() => ReactDOM.render(<Edytuj_wydzial apiurl={this.props.apiurl} token = {this.state.token}  idWydzialu={id} rok={this.state.rok} nazwawydzial={nazwa} kodwydzial={kod} username = {this.state.username} />, document.getElementById("root"))} >Edytuj</a>
                                <ul>
                                  {podrzedne.map(elem4 => {    
                                    const { id, nazwa, podrzedne } = elem4;
                                    return (
                                      <li key={id}>
                                        {nazwa}  - <a className="wydzialylink" onClick={() => ReactDOM.render(<Edytuj_wydzial apiurl={this.props.apiurl} token = {this.state.token}  idWydzialu={id} rok={this.state.rok} nazwawydzial={nazwa} kodwydzial={kod} username = {this.state.username} />, document.getElementById("root"))} >Edytuj</a>
                                        <ul>
                                          {podrzedne.map(elem5 => {    
                                            const { id, nazwa } = elem5;
                                            return <li key={id}>{nazwa} - <a className="wydzialylink" onClick={() => ReactDOM.render(<Edytuj_wydzial apiurl={this.props.apiurl} token = {this.state.token}  idWydzialu={id} rok={this.state.rok} nazwawydzial={nazwa} kodwydzial={kod} username = {this.state.username} />, document.getElementById("root"))} >Edytuj</a></li>;
                                          })}
                                        </ul>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </li>
    );
  })}
</ul>
</div>
</div>
</div>

</React.Fragment>
    );
  }
}

