import React from "react";
import ReactDOM from 'react-dom';
import './tabela.css';
import NumberFormat from 'react-number-format';



export default class Zestawieniahtml extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
        data: props.data, 
        text1: props.text1,
        date: new Date().toLocaleString(),
        }
    }

render() {
const { data, text1 } = this.state
return (
<React.Fragment>
<div className="m-1">
<table class="printable" cellSpacing="0" border="0">
<colgroup width="67"></colgroup>
<colgroup width="90"></colgroup>
<colgroup span="4" width="67"></colgroup>
<colgroup width="100"></colgroup> {/* @*nazwa*@ */}
<colgroup width="136"></colgroup> {/* @*obowiazujacyplan*@ */}
<colgroup width="110"></colgroup> {/* @*zwiekszenia*@ */}
<colgroup width="110"></colgroup> {/* @*zmniejszenia*@ */}
<colgroup width="150"></colgroup> {/* @*plan po*@ */}
<tbody>
    <tr>
        <td className="td-1" colSpan="2" height="47" align="center" valign="top"><i>Sygnatura</i></td>
        <td className="td-1" colSpan="8" align="center" valign="middle"><b><font size="3" color="#000080">WNIOSEK ZMIAN W BUDŻECIE - {text1} na  dzień {this.state.date} </font></b></td>
        <td className="td-1" align="center"></td>
    </tr>
    <tr>
        <td className="td-2" height="21" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="right"><b>Adresat:</b></td>
        <td className="td-3" colSpan="2" align="center"><b><font size="3">{data.adresat}</font></b></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        {/* @*<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" align="left"></td>*@ */}
        <td className="td-1">Część budżetu: </td>
        <td className="td-4" colSpan="4" align="center">{data.czescBudzetu}</td>
        <td align="left"></td>
        {/* @*<td align="left"></td>*@ */}
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" colSpan="2" height="18" align="center" valign="bottom"></td>
        <td align="left"></td>
        {/* @*<td style=" border-bottom: 1px solid #000000; border-left: 1px solid #000000" align="left"></td>*@ */}
        <td className="td-5">Wydatki na zadania:</td>
        <td className="td-6" colSpan="4" align="center">{data.rodzaje}</td>
        <td align="left"></td>
        {/* @*<td align="left"></td>*@ */}
        <td align="left"></td>
        <td className="td-3" align="center"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        {/* @*<td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000" align="left"></td>*@ */}
        <td className="td-1">Wydziały: </td>
        <td className="td-4" colSpan="4" align="center">{data.wydzialy}</td>
        <td align="left"></td>
        {/* @*<td align="left"></td>*@ */}
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" colSpan="2" height="18" align="center" valign="bottom"><i>pieczątka wydziału</i></td>
        <td align="left"><i></i></td>
        <td align="center"><i></i></td>
        <td align="left"><i></i></td>
        <td align="left"><i></i></td>
        <td align="left"><i></i></td>
        <td class="tabela" align="center"><b><i>obowiązujący plan</i></b></td>
        <td class="tabela" align="center"><b><i>zwiększenia</i></b></td>
        <td class="tabela" align="center"><b><i>zmniejszenia</i></b></td>
        <td class="tabela" align="center"><b><i>plan po zmianach</i></b></td>
    </tr>
    <tr>
        <td class="tabela" colSpan="7" height="21" align="center"><b><font size="3">OGÓŁEM {text1} </font></b></td>
        <td class="tabela" align="right"><b><font size="2"><NumberFormat value={data.przed} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></font></b></td>
        <td class="tabela" align="right"><b><font size="2"><NumberFormat value={data.zmniejszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></font></b></td>
        <td class="tabela" align="right"><b><font size="2"><NumberFormat value={data.zwiekszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></font></b></td>
        <td class="tabela" align="right"><b><font size="2"><NumberFormat value={data.wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></font></b></td>
    </tr>
    {data.dane.map(dane => {
    const { rozdzial, dzial, sumCol0, sumCol1, sumCol2, sumCol3, groups} = dane;
    return (<>
    <tr key="a">
        <td class="tabela" colSpan="7"><b><font size="3">Dział: {dzial} Rozdział: {rozdzial}</font></b></td>

        <td class="tabela" align="right"><b><NumberFormat value={sumCol0} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
        <td class="tabela" align="right"><b><NumberFormat value={sumCol1} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
        <td class="tabela" align="right"><b><NumberFormat value={sumCol2} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
        <td class="tabela" align="right"><b><NumberFormat value={sumCol3} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
    </tr>
    {groups.map(dane2 => {
    const { nazwaGrupy, sumCol0, sumCol1, sumCol2, sumCol3, dane} = dane2;
    return (<>
    <tr>
    <td class="tabela td-9" colspan="7"><b><font size="3">{nazwaGrupy}</font></b></td>
    <td class="tabela" align="right"><b><NumberFormat value={sumCol0} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
    <td class="tabela" align="right"><b><NumberFormat value={sumCol1} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
    <td class="tabela" align="right"><b><NumberFormat value={sumCol2} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
    <td class="tabela" align="right"><b><NumberFormat value={sumCol3} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></b></td>
    </tr>
    {dane.map(dane3 => {
        const { paragraf, p4, pozycja, nazwa, przed, zmniejszenia, zwiekszenia, wniosek} = dane3;
        return (<>
        <tr>
            <td class="tabela" height="17" align="center"> {paragraf}{p4}</td>
            <td class="tabela" height="17" align="center">{pozycja}</td>
            <td class="tabela" colspan="5" align="left" valign="middle">{nazwa}</td>
            <td class="tabela" align="right" valign="middle"><NumberFormat value={przed} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></td>
            <td class="tabela" align="right" valign="middle"><NumberFormat value={zmniejszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></td>
            <td class="tabela" align="right" valign="middle"><NumberFormat value={zwiekszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></td>
            <td class="tabela" align="right" valign="middle"><NumberFormat value={wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></td>
        </tr>
        </>); }
    )}

    </>);}
    )}

    </>);}
    )} 

   
         
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr></tr>
    <tr>
    <td colSpan="11"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left">Uwagi:</td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    {data.dane.map(dane4 => {
    const { uwagi } = dane4;
    return (<>
    {(uwagi || []).map(dane5 => {
    const { wydzial, uwaga} = dane5;
    return (<>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td colSpan="9"><b>{wydzial}</b></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td colSpan="9">{uwaga}</td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td colSpan="9"></td>
        <td className="td-3" align="left"></td>
    </tr>
    </>);}
    )}
    </>);}
    )}
    
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" height="17" align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="left"></td>
        <td className="td-3" align="left"></td>
    </tr>
    <tr>
        <td className="td-2" colSpan="3" height="17" align="center"><font face="Verdana">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</font></td>
        <td align="left"><font face="Verdana">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</font></td>
        <td align="left"><font face="Verdana">&hellip;&hellip;&hellip;&hellip;&hellip;</font></td>
        <td align="center"></td>
        <td align="center"><font face="Verdana">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</font></td>
        <td align="left"></td>
        <td className="td-3" colSpan="3" align="center"><font face="Verdana">&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;&hellip;..</font></td>
    </tr>
    <tr>
        <td className="td-2" colSpan="3" height="17" align="center"><i><font face="Verdana" size="1">podpis i pieczątka pracownika </font></i></td>
        <td align="left"><font face="Verdana" size="1"><br></br></font></td>
        <td align="left"><font face="Verdana" size="1">data</font></td>
        <td align="center"><i><font face="Verdana" size="1"><br></br></font></i></td>
        <td align="center"><i><font face="Verdana" size="1">podpis i pieczątka</font></i></td>
        <td align="left"></td>
        <td className="td-3" colSpan="3" align="center"><i><font face="Verdana" size="1">Akceptacja odpowiednio </font></i></td>
    </tr>
    <tr>
        <td className="td-2" colSpan="3" height="17" align="center"><i><font face="Verdana" size="1">wydziału merytorycznego</font></i></td>
        <td align="left"></td>
        <td align="left"></td>
        <td align="center"><i><font face="Verdana" size="1"><br></br></font></i></td>
        <td align="center"><i><font face="Verdana" size="1">Naczelnika Wydziału</font></i></td>
        <td align="left"></td>
        <td className="td-3" colSpan="3" align="center"><i><font face="Verdana" size="1">Prezydenta Miasta, Wiceprezydenta,</font></i></td>
    </tr>
    <tr>
        <td className="td-8" height="17" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-7" align="left"></td>
        <td className="td-6" align="left"></td>
    </tr>
</tbody>
</table>
</div>

</React.Fragment>
);
}
}
