import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavBar from './NavBar';
import Wydzialy from './Wydzialy';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import apiurl from './apiurl';
import './index.css';

export default class Edytuj_wydzial extends React.Component {
    constructor(props) {
      super(props);    
      this.state = {
      token : props.token,
      rok: props.rok,
      username: props.username,
      idWydzialu: props.idWydzialu,
      nazwawydzial : props.nazwawydzial,
      nazwastrony: 'Edytuj_wydzial',
    }
    }

componentWillMount(){
  ReactDOM.render(<NavBar token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
  this.onLista();
}

onChange = (a) => {
  this.setState({ [a.target.name]: a.target.value });
}



onLista = async () => {
  const res = await axios.get(apiurl+"api/WydzialyStruktura/GetWydzial?", { params : {rok : this.state.rok, idWydzialu : this.state.idWydzialu }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let kodwydzial = res.data.kod;
  let nadrzedny = res.data.idJednNadrzednej;
  let data11 = res.data.wydzialyDDL.map( category => ({ value: category.value, label: category.text , selected: category.selected }));
  this.setState({kodwydzial, data11, idj: data11[0].value, nadrzedny});
  this.check(data11);
}

check(data11){
  const nazwa = data11.map(function(data) {
    if(data.selected === true){
    return data.label;
    }
  });
  this.setState({ nadrzedny: nazwa })
}

Zapisz = (zapisz) => {
  zapisz.preventDefault();
  axios.put(apiurl+"api/WydzialyStruktura",
      {rok : this.state.rok , id : this.state.idWydzialu , nazwa : this.state.nazwawydzial , kod : this.state.kodwydzial , idJednNadrzednej : this.state.idj },
      {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
      .then(res => {
        swal("Zapisano zmianę!");
        ReactDOM.render(<Wydzialy token = {this.state.token} rok={this.state.rok}/>, document.getElementById("root"));
      })
  .catch(error => swal(error.response.data));
}

render() {
const { data11, nazwawydzial, kodwydzial, nadrzedny } = this.state;
return (
<React.Fragment>
<div class="container-fluid">
<h1>Edytuj wydział</h1>
<hr />

    <form id="function" name="form" class="form-horizontal" onSubmit={(zapisz) => this.Zapisz(zapisz)} > 
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Nazwa wydziału:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <input type="text" class="form-control" value={nazwawydzial} id="nazwawydzial" name="nazwawydzial"  onChange={this.onChange.bind(this)}></input>
                  </div>
          </div><br />
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Kod wydziału:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <input type="text" class="form-control" value={kodwydzial} id="kodwydzial" name="kodwydzial"  onChange={this.onChange.bind(this)}></input>
                  </div>
          </div><br />
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Jednostka nadrzędna:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <Select name="form-field-name" options={data11} placeholder={nadrzedny} onChange={d => this.setState({idj: d.value})} />
                  </div>
          </div> <br />
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"></div>
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1">
                  <button type="button"  class="btn btn-light pull-left"  onClick={() => ReactDOM.render(<Wydzialy username = {this.state.username} token = {this.state.token} rok={this.state.rok}/>, document.getElementById("root"))} id="function1" >Wróć</button>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1">
                  <button type="submit"  class="btn btn-light pull-left" id="function" >Zapisz</button>
                  </div>
          </div>
      </form>
  </div>
  </React.Fragment>
);
}
}
