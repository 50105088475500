import React from "react";
import Zestawieniahtml from './Zestawienia_html.js';
import PrintComponents from "react-print-components";

export default class Print extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: props.data }
    }

    render() {
        let { data } = this.state
        return (
        <PrintComponents trigger={<button type="button" class="btn btn-light float-right"><i class="fas fa-print"></i> Drukuj</button>} >
        <Zestawieniahtml data={data} />   
        </PrintComponents>
        );
    }
}
