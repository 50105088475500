import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavBar from './NavBar';
import Wydzialy from './Wydzialy';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import './index.css';
import apiurl from './apiurl';

export default class Dodaj_wydzial extends React.Component {
    constructor(props) {
      super(props);    
      this.state = {
      token : props.token,
      rok: props.rok,
      username: props.username,
      idWydzialu: props.idWydzialu,
      nazwawydzial : props.nazwawydzial,
      kodwydzial : props.kodwydzial,
      nazwastrony: 'Dodaj_wydzial',
    }
  }

componentWillMount(){
  ReactDOM.render(<NavBar  token = {this.state.token} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} username = {this.state.username} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
  this.onLista();
}

onChange = (a) => {
  this.setState({ [a.target.name]: a.target.value });
}

onLista = async () => {
  const res = await axios.get(apiurl+"api/WydzialyStruktura/GetAllWydzialy?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data11 = res.data.map( category => ({ value: category.value, label: category.text}));
  this.setState({data11, idj: data11[0].value, nadrzedny: data11[0].label});
}

Zapisz = (zapisz) => {
  zapisz.preventDefault();
  axios.post(apiurl+"api/WydzialyStruktura",
      {rok : this.state.rok , nazwa : this.state.nazwawydzial , kod : this.state.kodwydzial , idJednNadrzednej : this.state.idj },
      {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
      .then(res => {
        swal("Dodano!");
        ReactDOM.render(<Wydzialy token = {this.state.token} rok={this.state.rok}/>, document.getElementById("root"));
      })
  .catch(error => swal(error.response.data));
}

render() {
const { data11, nazwawydzial, kodwydzial, nadrzedny } = this.state;
return (
<React.Fragment>
<div class="container-fluid">
<h1>Dodaj wydział</h1>
<hr />
    <form id="function" name="form" class="form-horizontal" onSubmit={(zapisz) => this.Zapisz(zapisz)} > 
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Nazwa wydziału:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <input type="text" class="form-control" value={nazwawydzial} id="nazwawydzial" name="nazwawydzial"  onChange={this.onChange.bind(this)} required></input>
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Kod wydziału:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <input type="text" class="form-control" value={kodwydzial} id="kodwydzial" name="kodwydzial"  onChange={this.onChange.bind(this)} required></input>
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Jednostka nadrzędna:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <Select name="form-field-name" options={data11} placeholder={nadrzedny} onChange={d => this.setState({idj: d.value})} />
                  </div>
          </div> <br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"></div>
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1">
                  <button type="button"  class="btn btn-light pull-left"  onClick={() => ReactDOM.render(<Wydzialy apiurl={this.props.apiurl} username = {this.state.username} token = {this.state.token} rok={this.state.rok}/>, document.getElementById("root"))} id="function1" >Wróć</button>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1">
                  <button type="submit"  class="btn btn-light pull-left" id="function" >Zapisz</button>
                  </div>
          </div>
      </form>
      <hr />
  </div>
  </React.Fragment>
  );
  }
}
