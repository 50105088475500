import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import './index.css';
import NumberFormat from 'react-number-format';
import apiurl from './apiurl';



export default class Zadanie extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        errors: null, 
        token: props.token ,
        rok: props.rok ,
        id: props.id ,
        dwbp: props.dwbp ,
        dzial: props.dzial ,
        rozdzial: props.rozdzial ,
        paragraf: props.paragraf ,
        p4: props.p4 ,
        pozycja: props.pozycja ,
        idWydz: props.idWydz ,
        idRodz: props.idRodz ,
        Ukryta: props.Ukryta ,
        reload: props.reload,
        IDA: props.IDA,
        isLoadingZadanie: true,
        dodaj: false,
        pobrano: '',
        wniosek: '',
        open: props.open,
        renderZadania: props.renderZadania,
        data5: [],
      };
    }

    componentDidUpdate() {
      this.DataZadania();
    }

    shouldComponentUpdate(nextProps) {
      if (this.state.pobrano === '') {
        return nextProps.renderZadania === 'tak'
      }
      else {
        return false;
      }
    }
    
    componentWillReceiveProps(nextProps) {
      if(nextProps.reload === 'tak') {
        this.forceUpdate();
      }
    }



DataZadania() {
  axios
    .get(apiurl+"api/Zadania?", 
    {params : {rok: this.props.rok, idUchwaly: this.props.id, DWBP: this.props.dwbp, dzial: this.props.dzial, rozdzial: this.props.rozdzial, paragraf : this.props.paragraf + this.props.p4, 
    pozycja: this.props.pozycja, idWydz: this.props.idWydz, idRodz: this.props.idRodz},
    headers: { 'Authorization' : this.props.token}})
    .then(response => {
      this.setState({ data5 : response.data });
      let { data5 } = this.state;
      if (data5==''){
        this.setState({ data5, isLoadingZadanie: false, dodaj: true, pobrano: 'tak' });
      }
      else{
        this.setState({ data5, isLoadingZadanie: true, dodaj: true, pobrano: 'tak' });
      }
    })
    .catch(error => { 
    if (error.response.status === 403) {
    this.setState({ data5 : error.response.data });
    let { data5 } = this.state;
    if (data5==''){
      this.setState({ isLoadingZadanie: false, dodaj: false, pobrano: 'tak' });
    }
    else{
      this.setState({ data5, isLoadingZadanie: true, dodaj: false, pobrano: 'tak' });
    }
    } else { swal(error.response.data) }
    });
}

ChangeWniosek = (p) => { this.setState({ wniosek: p.target.value }) }

edit = (user5) => {
  if(this.state.wniosek === ''){ this.setState({ wniosek: `${user5.wniosek}` })};
  const nowyWniosek = this.state.wniosek.replace(",", ".");
  axios.put(apiurl+"api/Zadania?",
      {rok: this.state.rok, idUchwaly: this.state.id, idWydzialu: `${user5.idWydzialu}`, idRodz: `${user5.idRodz}`,
      dwbp: this.state.dwbp, dzial: `${user5.dzial}`,  rozdzial: `${user5.rozdzial}`, paragrafP4 : `${user5.paragraf}`+`${user5.p4}`, pozycja: `${user5.pozycja}`, idDzialania: `${user5.idDzialania}`, idZadania: `${user5.idZadania}`, wniosek: nowyWniosek },
      {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token} })
      .then(res => {
      swal("Zapisano zmiane!");
      })
      .catch(error => {
        swal(error.response.data)
      });
}
  
  render() {
  const { data5 } = this.state;
  return (
  <React.Fragment>
  <div class="table-responsive">
  <table class="table table-hover table-sm">
    <thead >
    <tr >
    <th scope="col" class="text-center"><b>Działanie</b></th>
    <th scope="col" class="text-center"><b>Zadanie</b></th>
    <th scope="col" class="text-center"><b>Symbol</b></th>
    <th scope="col" class="text-center"><b>Żródło finansowania</b></th>
    <th scope="col" class="text-center"><b>Koszt</b></th>
    <th scope="col" class="text-center"><b>Wniosek</b></th>
    <th scope="col" class="text-center"><b>Zapisz</b></th>
    </tr>
    </thead>
  <tbody>
  { this.state.isLoadingZadanie ? (
  data5.map(user5 => {
  const {  wniosek, dzialanie, zadanie, koszt, uchWydzStat, idZadania, zrodloFin, kod } = user5;
  return (
    <tr id={idZadania} >
    <td ><p>{dzialanie}</p></td>
    <td ><p>{zadanie}</p></td>
    <td ><p>{kod}</p></td>
    <td ><p>{zrodloFin}</p></td>
    <td ><p><NumberFormat value={koszt} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
    <td >
    {uchWydzStat === true ?(
    <NumberFormat value={wniosek} class="form-control" id={idZadania} onChange={(p) => this.ChangeWniosek(p)} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','}/>
        ) :(<NumberFormat value={wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} />)
    }
    </td>
    <td >
    {uchWydzStat === true ?(
    <button  onClick={() => this.edit(user5)} type="button" class="btn btn-light">Zapisz</button>
    ) :( null )
    }
    </td>
    </tr>
      );
    })
    ) : ( <tr ><td colSpan="6"><center><p>Brak zadań</p></center></td></tr> ) }
</tbody>
</table>
</div>

</React.Fragment>
      );
    }
  }
