import React from "react";
import Workbook from 'react-excel-workbook'


export default class Download extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: props.data }
    }

    render() {
        let { data } = this.state
        return (
            <Workbook filename="Zestawienie.xlsx" element={<button type="button" class="btn btn-light float-right"><i class="far fa-file-excel"></i> Export Excel</button>}>
                <Workbook.Sheet data={data} name="Zestawienie">
                    <Workbook.Column label="Dział" value="dzial"/>
                    <Workbook.Column label="Rozdział" value="rozdzial"/>
                    <Workbook.Column label="Paragraf" value="paragraf"/>
                    <Workbook.Column label="Pozycja" value="pozycja"/>
                    <Workbook.Column label="Rodzaj" value="rodzaj"/>
                    <Workbook.Column label="Wydzial" value="wydzial"/>
                    <Workbook.Column label="Nazwa" value="nazwa"/>
                    <Workbook.Column label="Plan początkowy" value="poczatkowy"/>
                    <Workbook.Column label="Przed zmianą" value="przed"/>
                    <Workbook.Column label="Zmniejszenia" value="zmniejszenia"/>
                    <Workbook.Column label="Zwiększenia" value="zwiekszenia"/>
                    <Workbook.Column label="Po zmianach" value="po"/>
                    <Workbook.Column label="Wniosek" value="wniosek"/>
                </Workbook.Sheet>
            </Workbook>
        );
    }
}
