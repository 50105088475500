import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavBar from './NavBar';
import Pokaz_zestawienie from './Pokaz_zestawienie';
import Lista from './Lista';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import './index.css';
import apiurl from './apiurl';
import Lista_zestawienia from './Lista_zestawienia.js';


export default class Gen_zestawienie extends React.Component {
    constructor(props) {
      super(props);    
      this.state = {
      token : props.token,
      rok: props.rok,
      username: props.username,
      id: props.id,
      kodoper: props.kodoper,
      nazwastrony: "Gen_zestawienie",
      selectedOption3: [],
      selectedOption4: [],
      items3: [],
      items4: [],
      check1: false,
      check2: false,
      dis1: false,
      dis2: true,
    }
    this.checkboxvalue1 = this.checkboxvalue1.bind(this);
    this.checkboxvalue2 = this.checkboxvalue2.bind(this);
  }

componentWillMount(){
  ReactDOM.render(<NavBar token = {this.state.token} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} username = {this.state.username} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
}
componentDidMount(){
  if(this.state.id === null){
    ReactDOM.render(<Lista token = {this.state.token} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} username = {this.state.username} kodoper = {this.state.kodoper} />, document.getElementById("root"));
  } else { this.onLista() }
}



onLista = async () => {
  const res = await axios.get(apiurl+"api/Raporty?", { params : {rok : this.state.rok, idUchwaly: this.state.id  }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data1 = res.data.grupaZestawien.map( category1 => ({ value: category1.value, label: category1.text}));
  let data2 = res.data.zestawienie.map( category2 => ({ value: category2.value, label: category2.text}));
  let data3 = res.data.rodzaj.map( category3 => ({ value: category3.value, label: category3.text}));
  let data4 = res.data.wydzialy.map( category4 => ({ value: category4.value, label: category4.text}));
  this.setState({data1, data2, data3, data4, value1: data1[0].value, text1: data1[0].label, value2: data2[0].value, text2: data2[0].label });
}

pokaz = (zapisz) => {
zapisz.preventDefault();
if(this.state.items3.length > 0){
  if(this.state.items4.length > 0){ReactDOM.render(<Pokaz_zestawienie text1={this.state.text1} token = {this.state.token} id = {this.state.id} rok = {this.state.rok} username = {this.state.username} grupaZestawienValue = {this.state.value1} zestawienieValue = {this.state.value2} rodzajValues = {this.state.items3} wydzialyValues = {this.state.items4} zZadaniami = {this.state.check1} pominStanyZerowe = {this.state.check2} />, document.getElementById("root"))}
  else { swal("Wybierz wydziały.")}  
}else { swal("Wybierz rodzaje.")}
}

value2 = (b) => {this.setState({ value2 : b.value }, () => { this.disable() });}

disable() {
  if(this.state.value2 === '1'){ this.setState({ dis1: false, dis2: true}); };
  if(this.state.value2 === '2'){ this.setState({ dis1: true, dis2: false}); };
}

wybrane3 = (selectedOption3) => { this.setState({ selectedOption3 }) };
wybrane4 = (selectedOption4) => { this.setState({ selectedOption4 }) };

checkboxvalue1() {this.setState(state => ({ check1: !state.check1 }))}
checkboxvalue2() {this.setState(state => ({ check2: !state.check2 }))}

polacz(zapisz) {
  let {items3, selectedOption3, items4, selectedOption4} = this.state;
  selectedOption3.map(item3 => { items3.push(item3.value) });
  selectedOption4.map(item4 => { items4.push(item4.value) });
  this.pokaz(zapisz); 
}


render() {
const { data1, data2, data3, data4, text1, text2, selectedOption3, selectedOption4, dis1, dis2 } = this.state;
return (
<React.Fragment>
<div class="container-fluid">
<h1>Generuj zestawienie</h1>
<hr />
    <form id="function" name="form" class="form-horizontal" onSubmit={(zapisz) => this.polacz(zapisz)} > 
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Grupa zestawień:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <Select name="form-field-name1" options={data1} placeholder={text1} onChange={a => this.setState({value1: a.value, text1: a.label})}  />
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Zestawienie:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <Select name="form-field-name2" options={data2}  placeholder={text2} onChange={(b) => this.value2(b) } />
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Rodzaj:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <Select name="form-field-name3" placeholder ="Wybierz..." options={data3} isMulti={true}   value={selectedOption3} onChange={this.wybrane3} closeMenuOnSelect={false} />
                  </div>
          </div> <br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Wydziały:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <Select name="form-field-name4" placeholder ="Wybierz..." options={data4} isMulti={true}   value={selectedOption4} onChange={this.wybrane4} closeMenuOnSelect={false} />
                  </div>
          </div> <br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Z zadaniami:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <input type="checkbox" onClick={this.checkboxvalue1} disabled={dis1}></input>
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Pomiń stany zerowe:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
                  <input type="checkbox" onClick={this.checkboxvalue2}></input>
                  </div>
          </div> <br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"></div>
                  <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"><button type="button"  class="btn btn-light pull-left"  onClick={() => ReactDOM.render(<Lista_zestawienia token = {this.state.token} username = {this.state.username}  kodoper={this.state.kodoper} rok={this.state.rok}/>, document.getElementById("root"))} >Wróć</button></div>
                  <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                  <button type="submit"  class="btn btn-light pull-left" id="function" >Generuj</button>
                  </div>
          </div>
      </form>
  </div>
  </React.Fragment>
      );
    }
  }

