import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import NavBar from './NavBar';
import axios from 'axios';
import swal from 'sweetalert';
import apiurl from './apiurl';
import Export_bestia from './Export_bestia';


export default class Lista extends React.Component {
  constructor(props) {
    super(props); 
    
    this.state = {
    token: props.token ,
    username: props.username ,
    kodoper: props.kodoper,
    rok: props.rok,
    nazwastrony: "Lista",
    data1: [],
    data2: [],
    dataf: [],
    user: [],
    sorta: '',
    filtr1: '',
    filtr2: '',
    filtr3: '',
    id: '',
    isVisible: false,
    isLoadingdane: false,
    isVisible3: true,
    brak: false,
    };
    this.onLista();
  }

  componentDidMount(){
    ReactDOM.render(<NavBar token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
    this.setState({ sorta: "1" }, () => { this.sprawdzenie() });
  }

  componentWillUpdate(nextProps){
    if(this.state.rok !== nextProps.rok ){
      this.setState({ rok : nextProps.rok}, () => { this.sprawdzenie() })
    } else {
      
    }
  }

  onChangee = (z) => {
   
    this.setState({ [z.target.name]: z.target.value }, () => { this.sprawdzenie() });

  }

  sprawdzenie = () => {
    const {sorta} = this.state;
    if (sorta === '1') {
      this.setState({ filtr1: '0', filtr2: '1',  filtr3: '2' }, () => { this.filtrowanie() });
    }
    if (sorta === '2') {
      this.setState({ filtr1: '3', filtr2: '31',  filtr3: '32' }, () => { this.filtrowanie() });
    }
    if (sorta === '3') {
      this.setState({ filtr1: '10', filtr2: '11',  filtr3: '12' }, () => { this.filtrowanie() });
    }
    if (sorta === '4') {
      this.setState({ filtr1: '20', filtr2: '21',  filtr3: '22' }, () => { this.filtrowanie() });
    }
    else {

    }
  }

  onLista = () => {
    axios
      .get(apiurl+"api/Uchwaly?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }})
      .then(response => 
        response.data.map(data1 => ({
          id: `${data1.iD_Uchwaly}`,
          lp: `${data1.lp}`,
          statid: `${data1.statId}`,
          status: `${data1.status}`,
          dataotw: `${data1.data_Otw}`,
          dataprzyj: `${data1.data_Przyj}`,
          nazwa: `${data1.nazwa}`,
        })) 
      )
      .then(data1 => {
        data1.sort((a, b) => a.lp - b.lp).reverse();
        this.setState({ data1 });
        this.filtrowanie();
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  zmiana = (user) => {
    this.setState({ id: `${user.id}`, nazwauchwaly: `${user.nazwa}`}, () => { this.dalej() });
  }

  filtrowanie() { 
  let {data1, filtr1, filtr2, filtr3 } = this.state;
  this.setState({ dataf : data1.filter(({statid}) => statid === filtr1 || statid === filtr2 || statid === filtr3  )}, () => { this.brakdanych() });
  }

  brakdanych() { 
    let {dataf} = this.state;
    if(dataf.length > 0){
    this.setState({ isVisible: true, isVisible3: false, isLoadingdane: true, brak: false });
    } else {
    this.setState({ isVisible: false, isVisible3: false, isLoadingdane: false, brak: true });
    }
  }

  sortowanieCyfry = (id) => {
    const { dataf, posortowane } = this.state;
    if (posortowane === 'tak'){
    dataf.sort((a, b) => a[id] - b[id]).reverse()
    this.setState({ dataf, posortowane: '' })
    }
    else {
    dataf.sort((a, b) => a[id] - b[id])    
    this.setState({ dataf, posortowane: 'tak' })
    }
  }

  sortowanieNazwy = (id) => {
    const { dataf, posortowane } = this.state;
    if (posortowane === 'tak'){
    dataf.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )).reverse();
    this.setState({ dataf, posortowane: '' })
    }
    else {
    dataf.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )); 
    this.setState({ dataf, posortowane: 'tak' })
    }  
  }

dalej = () => { ReactDOM.render(<App token = {this.state.token} username = {this.state.username} nazwauchwaly = {this.state.nazwauchwaly}  kodoper={this.state.kodoper} nazwastrony={this.state.nazwastrony} id = {this.state.id} rok={this.state.rok}/>, document.getElementById("root"));}


getParsedDate(strDate){
  var strSplitDate = String(strDate).split(' ');
  var date = new Date(strSplitDate[0]);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  
  var yyyy = date.getFullYear();
  if (dd < 10) {
      dd = '0' + dd;
  }
  if (mm < 10) {
      mm = '0' + mm;
  }
  date =  dd + "-" + mm + "-" + yyyy;
  return date.toString();
  }

render() {
const { sorta, dataf } = this.state;
return (
<React.Fragment>
<div class="container-fluid">
<h1>Lista uchwał - Budżet</h1>
<hr />
<ul className="nav nav-tabs" id="myTab" role="tablist">
  <li className="nav-item">
    <a className="nav-link active" id="Prowizorium-tab" data-toggle="tab" href="#Prowizorium" role="tab" aria-controls="Prowizorium" aria-selected="true" onClick={() => (this.setState({ sorta: "1" }, () => { this.sprawdzenie() }))}> Prowizorium</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="Autopoprawki-tab" data-toggle="tab" href="#Autopoprawki" role="tab" aria-controls="Autopoprawki" aria-selected="false" onClick={() => (this.setState({ sorta: "2" }, () => { this.sprawdzenie() }))}> Autopoprawki</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="Budzetowa-tab" data-toggle="tab" href="#Budzetowa" role="tab" aria-controls="Budzetowa" aria-selected="false" onClick={() => (this.setState({ sorta: "3" }, () => { this.sprawdzenie() }))}> Uchwała budżetowa</a>
  </li>
  <li className="nav-item">
    <a className="nav-link" id="Zmieniająca-tab" data-toggle="tab" href="#Zmieniająca" role="tab" aria-controls="Zmieniająca" aria-selected="false" onClick={() => (this.setState({ sorta: "4" }, () => { this.sprawdzenie() }))}> Uchwała zmieniająca</a>
  </li>
</ul>
<div className="tab-content" id="myTabContent">
<div className="tab-pane active card" id="Prowizorium" role="tabpanel" aria-labelledby="Prowizorium-tab">
<div class="table-responsive-xl card">
<table  class="table table-hover table-sm">   
  { this.state.isVisible ? (
<thead>
  <tr >
    <th  class="text-center point" onClick={() => this.sortowanieCyfry('lp')}><b>Lp</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('nazwa')}><b>Nazwa</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('status')}><b>Status</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataotw')}><b>Data otwarcia</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataprzyj')}><b>Data przyjęcia </b></th>
    <th  class="text-center"><b></b></th>
  </tr>
</thead>
) : null }
<tbody>
{ this.state.isLoadingdane ? (
  dataf
  .map(user => {
  const { id, lp, nazwa, status, dataotw, dataprzyj } = user;
  const dataf1 = this.getParsedDate(dataotw);
  const dataf2 = this.getParsedDate(dataprzyj);
  return (
      <tr key={lp}>
        <td><p>{lp}</p></td>
        <td><p>{nazwa}</p></td>
        <td><p>{status}</p></td>
        <td><p>{dataf1}</p></td>
        <td><p>{dataprzyj === "null" ? null : (<p>{dataf2}</p>) }</p></td>
        <td>
        <button  onClick={() => this.zmiana(user)} type="button" name="id" class="btn btn-sm btn-light m-1">Wybierz uchwałe</button> <button type="button"  class="btn btn-sm btn-light m-1"  onClick={() => ReactDOM.render(<Export_bestia token = {this.state.token} rok={this.state.rok} idUchwaly={id}/>, document.getElementById("root"))} >Export</button>
        </td>
      </tr>
      );
    })
    ) : null }
{ this.state.isVisible3 ? ( <tr ><td colSpan="13"><center><p>Wybierz wartości</p></center></td></tr> ) : null }
{ this.state.brak ? ( <tr ><td colSpan="13"><center><p>Brak danych</p></center></td></tr> ) : null }
</tbody>
</table>
</div>
</div>

<div className="tab-pane" id="Autopoprawki" role="tabpanel" aria-labelledby="Autopoprawki-tab">
<div class="table-responsive-xl card">
<table  class="table table-hover table-sm">   
  { this.state.isVisible ? (
<thead>
  <tr >
    <th  class="text-center point" onClick={() => this.sortowanieCyfry('lp')}><b>Lp</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('nazwa')}><b>Nazwa</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('status')}><b>Status</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataotw')}><b>Data otwarcia</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataprzyj')}><b>Data przyjęcia </b></th>
    <th  class="text-center point"><b></b></th>
  </tr>
</thead>
) : null }
<tbody>
{ this.state.isLoadingdane ? (
  dataf
  .map(user => {
  const { id, lp, nazwa, status, dataotw, dataprzyj } = user;
  const dataf1 = this.getParsedDate(dataotw);
  const dataf2 = this.getParsedDate(dataprzyj);
  return (
      <tr key={lp}>
        <td><p>{lp}</p></td>
        <td><p>{nazwa}</p></td>
        <td><p>{status}</p></td>
        <td><p>{dataf1}</p></td>
        <td><p>{dataprzyj === "null" ? null : (<p>{dataf2}</p>) }</p></td>
        <td>
        <button  onClick={() => this.zmiana(user)} type="button" name="id" class="btn btn-sm btn-light m-1">Wybierz uchwałe</button>
        <button type="button"  class="btn btn-sm btn-light m-1"  onClick={() => ReactDOM.render(<Export_bestia token = {this.state.token} rok={this.state.rok} idUchwaly={id}/>, document.getElementById("root"))} >Export</button>
        </td>
      </tr>
      );
    })
    ) : null }
{ this.state.isVisible3 ? ( <tr ><td colSpan="13"><center><p>Wybierz wartości</p></center></td></tr> ) : null }
{ this.state.brak ? ( <tr ><td colSpan="13"><center><p>Brak danych</p></center></td></tr> ) : null }
</tbody>
</table>
</div>
</div>

<div className="tab-pane" id="Budzetowa" role="tabpanel" aria-labelledby="Budzetowa-tab">
<div class="table-responsive-xl card">
<table  class="table table-hover table-sm">   
  { this.state.isVisible ? (
<thead>
  <tr >
    <th  class="text-center point" onClick={() => this.sortowanieCyfry('lp')}><b>Lp</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('nazwa')}><b>Nazwa</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('status')}><b>Status</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataotw')}><b>Data otwarcia</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataprzyj')}><b>Data przyjęcia </b></th>
    <th  class="text-center"><b></b></th>
  </tr>
</thead>
) : null }
<tbody>
{ this.state.isLoadingdane ? (
  dataf
  .map(user => {
  const { id, lp, nazwa, status, dataotw, dataprzyj } = user;
  const dataf1 = this.getParsedDate(dataotw);
  const dataf2 = this.getParsedDate(dataprzyj);
  return (
      <tr key={lp}>
        <td><p>{lp}</p></td>
        <td><p>{nazwa}</p></td>
        <td><p>{status}</p></td>
        <td><p>{dataf1}</p></td>
        <td><p>{dataprzyj === "null" ? null : (<p>{dataf2}</p>) }</p></td>
        <td>
        <button  onClick={() => this.zmiana(user)} type="button" name="id" class="btn btn-sm btn-light m-1">Wybierz uchwałe</button>
        <button type="button"  class="btn btn-sm btn-light m-1"  onClick={() => ReactDOM.render(<Export_bestia token = {this.state.token} rok={this.state.rok} idUchwaly={id}/>, document.getElementById("root"))} >Export</button>
        </td>
      </tr>
      );
    })
    ) : null }
{ this.state.isVisible3 ? ( <tr ><td colSpan="13"><center><p>Wybierz wartości</p></center></td></tr> ) : null }
{ this.state.brak ? ( <tr ><td colSpan="13"><center><p>Brak danych</p></center></td></tr> ) : null }
</tbody>
</table>
</div>
</div>

<div className="tab-pane" id="Zmieniająca" role="tabpanel" aria-labelledby="Zmieniająca-tab">
<div class="table-responsive-xl card">
<table  class="table table-hover table-sm">   
  { this.state.isVisible ? (
<thead>
  <tr >
    <th  class="text-center point" onClick={() => this.sortowanieCyfry('lp')}><b>Lp</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('nazwa')}><b>Nazwa</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('status')}><b>Status</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataotw')}><b>Data otwarcia</b></th>
    <th  class="text-center point" onClick={() => this.sortowanieNazwy('dataprzyj')}><b>Data przyjęcia </b></th>
    <th  class="text-center"><b></b></th>
  </tr>
</thead>
) : null }
<tbody>
{ this.state.isLoadingdane ? (
  dataf
  .map(user => {
  const { id, lp, nazwa, status, dataotw, dataprzyj } = user;
  const dataf1 = this.getParsedDate(dataotw);
  const dataf2 = this.getParsedDate(dataprzyj);
  return (
      <tr key={lp}>
        <td><p>{lp}</p></td>
        <td><p>{nazwa}</p></td>
        <td><p>{status}</p></td>
        <td><p>{dataf1}</p></td>
        <td><p>{dataprzyj === "null" ? null : (<p>{dataf2}</p>) }</p></td>
        <td>
        <button  onClick={() => this.zmiana(user)} type="button" name="id" class="btn btn-sm btn-light m-1">Wybierz uchwałe</button>
        <button type="button"  class="btn btn-sm btn-light m-1"  onClick={() => ReactDOM.render(<Export_bestia token = {this.state.token} rok={this.state.rok} idUchwaly={id}/>, document.getElementById("root"))} >Export</button>
        </td>
      </tr>
      );
    })
    ) : null }
{ this.state.isVisible3 ? ( <tr ><td colSpan="13"><center><p>Wybierz wartości</p></center></td></tr> ) : null }
{ this.state.brak ? ( <tr ><td colSpan="13"><center><p>Brak danych</p></center></td></tr> ) : null }
</tbody>
</table>
</div>
</div>



</div>
</div>
</React.Fragment>
    );
  }
}

