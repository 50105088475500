import React from 'react';
import ReactDOM from 'react-dom';
import top from './top.png';
import ikona from './favico.ico';
import apiurl from './apiurl';
import axios from 'axios';
import Logowanie from './Log';
import swal from 'sweetalert';
import Info from './Info';
import Popup from "reactjs-popup";



export default class Header extends React.Component {
    constructor(props) {
    super(props);    
      this.state = {
      isToggleOn: false,
      info: false,
      };
      this.hideMenuClick = this.hideMenuClick.bind(this);
      this.closeModal = this.closeModal.bind(this);

    }
  

    componentDidMount() {
      window.addEventListener("resize", this.resize.bind(this));
      this.resize();
    }

   

    resize() {
      const nav = document.getElementById('header');
      const title = document.getElementById('nav');
      const root = document.getElementById('root');
      if (window.innerWidth <= 768) {
        nav.classList.add('schowaj')
        title.classList.add('schowaj')
        root.classList.add('pad2')
        root.classList.remove('pad')
      }
      else {
        nav.classList.remove('schowaj')
        title.classList.remove('schowaj')
        root.classList.add('pad')
        root.classList.remove('pad2')
      }
    }


    componentWillReceiveProps(nextProps) {
      this.setState({ token: nextProps.token })  
    }
    
    hideMenuClick() {
      this.setState(prevState => ({
          isToggleOn: !prevState.isToggleOn
      }));
      const nav = document.getElementById('header');
      const title = document.getElementById('nav');
      const root = document.getElementById('root');

      if (!this.state.isToggleOn) {
        nav.classList.add('schowaj')
        title.classList.add('schowaj')
        root.classList.add('pad2')
        root.classList.remove('pad')
      }
      else {
          nav.classList.remove('schowaj')
          title.classList.remove('schowaj')
          root.classList.add('pad')
          root.classList.remove('pad2')

      }
  }

  logout = () => {
    if(sessionStorage.getItem('token') === null) {
      ReactDOM.render(<Logowanie />, document.getElementById("root"));
    } else {
    axios.post(apiurl+"api/Auth/logout", null , 
        {headers : { 'Authorization' : sessionStorage.getItem('token') } })
        .then(() => {
          localStorage.clear();
          sessionStorage.clear();
          ReactDOM.render(<Logowanie />, document.getElementById("root"));
        })
    .catch(() => swal("Nie wylogowano!"));
    }
  }

  closeModal () {
    this.setState({ info: false })
  }

  render() {
  
  return (
  <React.Fragment>
  <header className="main-header" style={{backgroundImage:`url(${top})`}}>
  
    <div id="logo" className="logo main-sidebar">
    <p className="logo-lg" onClick={() => (this.setState({ info: true }))}><div  className="align-middle"><img src={ikona} width="28" height="28"></img> </div>  eWizja</p>
    </div>
    <nav className="navbar navbar-static-top">
      <a href="#" onClick={() => this.hideMenuClick()}>
        <i className="nav__icon fas fa-align-justify fa-w-14 fa-3px"></i>
      </a>
      {sessionStorage.getItem('token') !== null ? (<button type="button" className="btn btn-primary float-right" onClick={this.logout}><i className="fas fa-sign-out-alt"></i>Wyloguj</button>) : null }
    </nav>
  </header >

  <Popup open={this.state.info} closeOnDocumentClick onClose={this.closeModal}>
  <div class="okno">
  <a class="close" onClick={this.closeModal}>
  &times;
  </a>
  <Info />
  </div>
  </Popup>

  </React.Fragment>
  );
  }
  }
