import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import './index.css';
import NumberFormat from 'react-number-format';
import Select from 'react-select';
import apiurl from './apiurl';


export default class Zadanie_Pop extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        errors: null, 
        token: props.token ,
        rok: props.rok ,
        idUchwaly: props.idUchwaly ,
        dzial: props.dzial ,
        rozdzial: props.rozdzial ,
        paragrafP4: props.paragrafP4 ,
        pozycja: props.pozycja ,
        dwbp: props.dwbp ,
        idWydz: props.idWydz ,
        idRodz: props.idRodz ,
        formularzpop: props.formularzpop,
        pokazdodaj: false,
        data4: [],
      };
    }

    componentDidMount() {
      this.getData();
      this.onListaZadania();
      this.onListaZrodla();
    }

    ChangeWniosekpop = (p) => {
      let { data4 } = this.state;
      data4 = data4.map(obj => {
        if (obj.idZadania === p.target.id) {
          obj.wniosek = p.target.value
        }
        return obj;
      });
      this.setState({
        data4
      });
    }
    
    editpop = (user4) => {
      const nowyWp = `${user4.wniosek}`.replace(",", ".");
      axios.put(apiurl+"api/Zadania?",
          {rok: this.state.rok, idUchwaly: this.state.idUchwaly, idWydzialu: `${user4.idWydzialu}`, idRodz: `${user4.idRodz}`,
          dwbp: this.state.dwbp, dzial: `${user4.dzial}`,  rozdzial: `${user4.rozdzial}`, paragrafP4 : `${user4.paragraf}`+`${user4.p4}`, pozycja: `${user4.pozycja}`, idDzialania: `${user4.idDzialania}`, idZadania: `${user4.idZadania}`, wniosek: nowyWp },
          {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token} })
          .then(res => {
          swal("Zapisano zmiane!");
          })
          .catch(error => {
            swal(error.response.data)
          });
    }

    dodajpop = (p) => {
      p.preventDefault();
      axios.post(apiurl+"api/Zadania",
          {rok: this.state.rok, idUchwaly: this.state.idUchwaly, idZadania: this.state.idZadaniadodaj, dzial: this.state.dzial, rozdzial: this.state.rozdzial, paragrafP4: this.state.paragrafP4,
          pozycja: this.state.pozycja, dwbp: this.state.dwbp, idWydz: this.state.idWydz, idRodz: this.state.idRodz, opis: this.state.opis, kwota: this.state.Wniosekd, zrodloFin: this.state.zrodlododaj },
          {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
          .then(res => {
            swal("Dodano!");
            this.getData();
            this.setState({ formularzpop : true })
          })
      .catch(error => swal(error.response.data));
    }

    getData = () => {
      axios
        .get(apiurl+"api/Zadania?", 
        {params : {rok: this.state.rok, idUchwaly: this.state.idUchwaly, idZadania: this.state.idZadania, dzial: this.state.dzial, rozdzial: this.state.rozdzial, paragraf: this.state.paragrafP4,
        pozycja: this.state.pozycja, DWBP: this.state.dwbp, idWydz: this.state.idWydz, idRodz: this.state.idRodz, opis: this.state.opis, kwota: this.state.kwota},
        headers: { 'Authorization' : this.state.token }})
        .then(response => {
          this.setState({ data4 : response.data });
          let { data4 } = this.state;
          if (data4==''){
            this.setState({ isLoadingpop: false, pobrano: 'tak', pokazdodaj: true });
          }
          else{
            this.setState({ data4, isLoadingpop: true, pobrano: 'tak', pokazdodaj: true });
          }
        })
        .catch(error => { 
          if (error.response.status === 403) {
            this.setState({ data4 : error.response.data });
            let { data4 } = this.state;
            if (data4==''){
              this.setState({ isLoadingpop: false, pobrano: 'tak', pokazdodaj: false });
            }
            else{
              this.setState({ data4, isLoadingpop: true, pobrano: 'tak', pokazdodaj: false });
            }
          } else { swal(error.response.data); this.setState({ pokazdodaj: false }) }
        })
    }

    onListaZadania = async () => {
      const res = await axios.get(apiurl+"api/Listy/GetZadania?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
      let data = res.data.map( category => ({ value: category.value, label: category.text}));
      this.setState({data, idZadaniadodaj: data[0].value, Zadaniat: data[0].label});
    }

    onListaZrodla = async () => {
      const res = await axios.get(apiurl+"/api/Listy/GetZrodlaFin?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
      let datafin = res.data.map( category => ({ value: category.value, label: category.text}));
      this.setState({datafin, zrodlododaj: datafin[0].value, zrodlotext: datafin[0].label});
    }

    onChange = (z) => { this.setState({ [z.target.name]: z.target.value }) }
    
  
render() {
const { data, datafin, data4, Zadaniat, zrodlotext } = this.state;
return (
<React.Fragment>
{this.state.formularzpop ? (<>
  <h3>Zadania</h3>
  {this.state.pokazdodaj ? (<button  onClick={() => this.setState({ formularzpop : false})} type="button" class="btn btn-light">Dodaj zadanie</button>) : null }
  <hr width="75%" align="left"/>
  <div class="table-responsive-xl card">
  <table  class="table table-hover table-sm">   
  <thead >
    <tr >
      <th scope="col" class="text-center"><b>Działanie</b></th>
      <th scope="col" class="text-center"><b>Zadanie</b></th>
      <th scope="col" class="text-center"><b>Symbol</b></th>
      <th scope="col" class="text-center"><b>Żródło finansowania</b></th>
      <th scope="col" class="text-center"><b>Koszt</b></th>
      <th scope="col" class="text-center"><b>Wniosek</b></th>
      <th scope="col" class="text-center"><b>Zapisz</b></th>
    </tr>
  </thead>
  <tbody>
    {this.state.isLoadingpop ? (
    data4.map(user4 => {
      const {  wniosek, idZadania, dzialanie, zadanie, koszt, uchWydzStat, zrodloFin, kod} = user4;
      return (<>
        <tr id={idZadania}>
        <td ><p>{dzialanie}</p></td>
        <td ><p>{zadanie}</p></td>
        <td ><p>{kod}</p></td>
        <td ><p>{zrodloFin}</p></td>
        <td ><p><NumberFormat value={koszt} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
        <td >
        {uchWydzStat === true ?(
        <NumberFormat value={wniosek} class="form-control" id={idZadania} onChange={(p) => this.ChangeWniosekpop(p)} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','}/>
        ) :(<NumberFormat value={wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} />       )
        }
        </td>
        <td >
        {uchWydzStat === true ?(
        <button  onClick={() => this.editpop(user4)} type="button" class="btn btn-light">Zapisz</button>
        ) :( null )
        }
        </td>
        </tr> 
        </>);
        })
        
      ) : ( <tr ><td colSpan="5"><center><p>Brak zadań</p></center></td></tr> ) } </tbody>
      </table></div></>) 
      : 
      (<div className="container"> 
      <h3>Dodaj zadanie</h3>
      <hr width="75%" align="left"/>

        <form id="dodaj-zadanie" name="form-dodaj-zadania" class="form-horizontal" onSubmit={(p) => this.dodajpop(p)}> 
            <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Wybierz działanie:</label></div>
                  <div class="col-xs-6 col-sm-6 col-md-5 col-lg-5">
                  <Select name="form-field-name" options={data} placeholder={Zadaniat} onChange={c => this.setState({idZadaniadodaj: c.value})}/>
                  </div>
            </div><br></br>
            <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Opis:</label></div>
                <div class="col-xs-6 col-sm-6 col-md-5 col-lg-5"><input type="text" class="form-control" id="opis" name="opis" placeholder="Wpisz opis" onChange={(z) => this.onChange(z)}></input></div>
            </div><br></br>
            <div class="row">
                    <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Wniosek:</label></div>
                    <div class="col-xs-6 col-sm-6 col-md-5 col-lg-5"><input type="number" class="form-control" required title="Wpisz wniosek" placeholder="0,00" pattern="[0-9]+([\.,][0-9]+)?" step="0.01" id="Wniosekd" name="Wniosekd" onChange={(z) => this.onChange(z)}></input></div>
            </div> <br></br>
            <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Wybierz źródło finansowania:</label></div>
                  <div class="col-xs-6 col-sm-6 col-md-5 col-lg-5">
                  <Select name="form-field-name" options={datafin} placeholder={zrodlotext} onChange={c => this.setState({zrodlododaj: c.value})}/>
                  </div>
            </div><br></br>
            <div class="row">
                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
                    <div class="col-xs-6 col-sm-6 col-md-5 col-lg-5">
                    <button  onClick={() => this.setState({ formularzpop : true})} type="button" class="btn btn-light pull-left" >Anuluj</button>
                    <button type="submit"  class="btn btn-light m-3" id="function" >Zapisz</button>
                    </div>
            </div>
        </form>
      </div>) }

</React.Fragment>
      );
    }
  }
