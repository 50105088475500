import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from './NavBar';
import Gen_zestawienie from './Gen_zestawienie';
import axios from 'axios';
import apiurl from './apiurl';
import './index.css';


export default class Lista_zestawienia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    token: props.token ,
    username: props.username ,
    kodoper: props.kodoper,
    rok: props.rok,
    nazwastrony: "Lista_zestawienia",
    data1: [],
    user: [],
    id: '',
    isVisible: false,
    isLoadingdane: false,
    isVisible3: true,
    };
    this.onLista();
  }

  componentWillMount(){
    ReactDOM.render(<NavBar token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
  }

  onLista = () => {
    axios
      .get(apiurl+"api/Uchwaly?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }})
      .then(response =>
        response.data.map(data1 => ({
          id: `${data1.iD_Uchwaly}`,
          lp: `${data1.lp}`,
          statid: `${data1.statId}`,
          status: `${data1.status}`,
          dataotw: `${data1.data_Otw}`,
          dataprzyj: `${data1.data_Przyj}`,
          nazwa: `${data1.nazwa}`,
        }))
      )
      .then(data1 => {
        data1.sort((a, b) => a.lp - b.lp).reverse();
        this.setState({ data1, isVisible: true, isVisible3: false, isLoadingdane: true });
      })
      .catch(error => this.setState({ error, isLoading: false }));
  }

  zmiana = (s) => {this.setState({ [s.target.name]: s.target.value }, () => { this.dalej() }) }

  sortowanieCyfry = (id) => {
    const { data1, posortowane } = this.state;
    if (posortowane === 'tak'){
    data1.sort((a, b) => a[id] - b[id]).reverse()
    this.setState({ data1, posortowane: '' })
    }
    else {
    data1.sort((a, b) => a[id] - b[id])    
    this.setState({ data1, posortowane: 'tak' })
    }
  }

  sortowanieNazwy = (id) => {
    const { data1, posortowane } = this.state;
    if (posortowane === 'tak'){
      data1.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )).reverse();
    this.setState({ data1, posortowane: '' })
    }
    else {
    data1.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )); 
    this.setState({ data1, posortowane: 'tak' })
    }  
  }

dalej = () => {ReactDOM.render(<Gen_zestawienie token = {this.state.token} username = {this.state.username}  kodoper={this.state.kodoper} nazwastrony={this.state.nazwastrony} id = {this.state.id} rok={this.state.rok}/>, document.getElementById("root"));}


getParsedDate(strDate){
  var strSplitDate = String(strDate).split(' ');
  var date = new Date(strSplitDate[0]);
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  
  var yyyy = date.getFullYear();
  if (dd < 10) {
      dd = '0' + dd;
  }
  if (mm < 10) {
      mm = '0' + mm;
  }
  date =  dd + "-" + mm + "-" + yyyy;
  return date.toString();
  }

render() {
const { data1, rok, username } = this.state;

return (
<React.Fragment>

<div class="container-fluid">
<h1>Lista uchwał - Zestawienia</h1>
<hr />
<div class="table-responsive-xl card">
<table  class="table table-hover table-sm">   
  { this.state.isVisible ? (
<thead>
  <tr >
    <th scope="col" class="text-center point" onClick={() => this.sortowanieCyfry('lp')}><b>Lp</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieNazwy('nazwa')}><b>Nazwa</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieNazwy('status')}><b>Status</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieNazwy('dataotw')}><b>Data otwarcia</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieNazwy('dataprzyj')}><b>Data przyjęcia </b></th>
    <th scope="col" class="text-center point"><b></b></th>
  </tr>
</thead>
) : null }
<tbody>
{ this.state.isLoadingdane ? (
  data1
  .map(user => {
  const { id, lp, nazwa, status, dataotw, dataprzyj } = user;
  const dataf1 = this.getParsedDate(dataotw);
  const dataf2 = this.getParsedDate(dataprzyj);
  return (
      <tr key={lp}>
        <td><p>{lp}</p></td>
        <td><p>{nazwa}</p></td>
        <td><p>{status}</p></td>
        <td><p>{dataf1}</p></td>
        <td>{dataprzyj === "null" ? null : (<p>{dataf2}</p>) }</td>
        <td>
        <button  onClick={(s) => this.zmiana(s)} type="button" value={id} id={lp} name="id" class="btn btn-sm btn-light">Wybierz uchwałe</button>
        </td>
      </tr>
      );
    })
    ) : null }
{ this.state.isVisible3 ? ( <tr ><td colSpan="13"><center><p>Wybierz wartości</p></center></td></tr> ) : null }
</tbody>
</table>
</div>
</div>

</React.Fragment>
    );
  }
}

