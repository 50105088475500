import React from 'react';
import ReactDOM from 'react-dom';
import Lista from './Lista';
import Zadanie from './Zadanie';
import Zadanie_Pop from './Zadanie_Pop';
import Uwagi_Pop from './Uwagi_Pop';
import NavBar from './NavBar';
import Dodaj_plan from './Dodaj_plan';
import axios from 'axios';
import Popup from "reactjs-popup";
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import './index.css';
import ChangePswd from './ChangePswd';
import apiurl from './apiurl';


export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    token: props.token ,
    username: props.username ,
    id: props.id ,      
    rok: props.rok,
    nazwauchwaly: props.nazwauchwaly,  
    nazwastrony: 'App',
    data1: [],
    data2: [],
    data3: [],
    data4: [],
    data5: [],
    data6: [],
    user: {},
    user4: [],
    idw: "-1",
    Ukryta: 'hide',
    renderZadania: 'nie',
    posortowane: '',
    Wniosek : '',
    pobrano: '',
    reload: '',
    open: false,
    openuwagi: false,
    isLoading: true,
    isLoadingdane: false,
    isLoadingpop: true,
    errors: null, 
    isVisible: false,
    isVisible2: false,
    isVisible3: true,
    formularzpop: true,
    tabelaVisible: true,
    WniosekEdycja: true,
    isLoadingPrzychody: false,
    isVisiblePrzychHead: false,
    sprawdzam: 'zero',
    };
    this.closeModal = this.closeModal.bind(this);
    this.closeModalUwagi = this.closeModalUwagi.bind(this);

  }

  componentDidMount(){
    ReactDOM.render(<NavBar token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
  }


  onChangee = (z) => {
    if (z.target.value === '5' || z.target.value === '6'){
      this.setState({ [z.target.name]: z.target.value, Ukryta: 'hide' }, () => { this.onLista2(); this.getDataPrzychody() });
    }
    else{
      this.setState({ [z.target.name]: z.target.value, Ukryta: 'hide', reload: 'nie' }, () => { this.onLista2(); this.getData() });
    }
  }

  onChangeeee = (y) => {
    if ( this.state.dwbp === '5' || this.state.dwbp === '6'){
      let index = y.nativeEvent.target.selectedIndex;
      let label = y.nativeEvent.target[index].text;
      let value = y.target.value;
      this.setState({ idw : value, nazwaw : label }, () => { this.getDataPrzychody() });
    }
    else{
      let index = y.nativeEvent.target.selectedIndex;
      let label = y.nativeEvent.target[index].text;
      let value = y.target.value;
      this.setState({ idw : value, nazwaw : label, Ukryta: 'hide', reload: 'nie'  }, () => { this.getData() });
    }

  }

  onChangeUkryj = () => {
    if (this.state.Ukryta==='hide'){
      this.setState({ Ukryta: '', renderZadania: 'tak', reload: 'tak'} );
    }
    else{
      this.setState({ Ukryta: 'hide', renderZadania: 'nie', reload: 'nie' });
    }
  }

  odswiez() {
    if ( this.state.dwbp === '5' || this.state.dwbp === '6'){
      this.setState({ Ukryta: 'hide', reload: 'tak' }, () => { this.getDataPrzychody() });
    }
    
    else{
    this.setState({ Ukryta: 'hide', reload: 'tak' }, () => { this.getData() });
    }
  }

  onLista2 = () => {
    axios
      .get(apiurl+"api/Listy/GetWydzialy?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }})
      .then(response =>
        response.data.map(data3 => ({
          idw: `${data3.value}`,
          nazwaw: `${data3.text}`,
        }))
      )
      .then(data3 => {
        this.setState({
          data3,
          isLoading: false
        });
      })
      .catch(error => swal(error.response.data) , this.setState({ isLoading: false }));
  }

getData() {
  axios
    .get(apiurl+"api/Budzet?", {params : {rok : this.state.rok, idUchwaly : this.state.id, idWydzialu : this.state.idw, DWBP : this.state.dwbp}, headers: { 'Authorization' : this.state.token }})
    .then(response =>
      response.data.map(data2 => ({
        IDA: `${data2.id}`,
        DW: `${data2.dw}`,
        BP: `${data2.bp}`,
        Dzial: `${data2.dzial}`,
        Rozdzial: `${data2.rozdzial}`,
        Paragraf: `${data2.paragraf}`,
        Pozycja: `${data2.pozycja}`,
        idRodzaju: `${data2.idRodzaj}`,
        Rodzaj: `${data2.rodzajNazwa}`,
        idWydzialu: `${data2.idWydzial}`,
        Wydzial: `${data2.wydzial}`,
        Opis: `${data2.opis}`,
        Przed: `${data2.przed}`.replace('.', ','),
        Zmiany: `${data2.zmiany}`,
        Po: `${data2.po}`.replace('.', ','),
        poWniosku: `${data2.poWniosku}`.replace('.', ','),
        zmiany: `${data2.zmiany}`,
        P4: `${data2.p4}`, 
        Wniosek: `${data2.wniosek}`.replace('.', ','),
        uchWydzStat: `${data2.uchWydzStat}`,
        uchWydzStatZad: `${data2.uchWydzStatZad}`,
        Ukryta: 'hide',
      }))
    )
    .then(data2 => {
      if (data2==''){
        this.setState({ isLoadingdane: false, isVisible: false, isVisible2: true, isVisible3: false, isLoadingPrzychody: false, isVisiblePrzychHead: false });
      }
      else{
        this.setState({ data2, isLoadingdane: true, isLoadingPrzychody: false, isVisible: true, isVisible2: false, isVisible3: false, isVisiblePrzychHead: false });
      }
    })
    .catch(error => swal(error.response.data.message) , this.setState({ isVisible3: true }));
  }

getDataPrzychody() {

  axios
    .get(apiurl+"api/BudzetPR?", {params : {rok : this.state.rok, idUchwaly : this.state.id, idWydzialu : this.state.idw, DWBP : this.state.dwbp}, headers: { 'Authorization' : this.state.token }})
    .then(response =>
      response.data.map(data6 => ({
        IDP: `${data6.id}`,
        Paragraf: `${data6.paragraf}`,
        Pozycja: `${data6.pozycja}`,
        Wydzial: `${data6.wydzial}`,
        Opis: `${data6.opis}`,
        Wniosek: `${data6.wniosek}`.replace('.', ','),
        Przed: `${data6.przed}`.replace('.', ','),
        Zmiany: `${data6.zmiany}`.replace('.', ','),
        Po: `${data6.po}`.replace('.', ','),
        poWniosku: `${data6.poWniosku}`.replace('.', ','), 
      }))
    )
    .then(data6 => {
      if (data6==''){
        this.setState({ isLoadingdane: false, isVisible: false, isVisible2: true, isVisible3: false, isLoadingPrzychody: false, isVisiblePrzychHead: false });
      }
      else{
        this.setState({ data6, isLoadingdane: false, isLoadingPrzychody: true, isVisible: false, isVisible2: false, isVisible3: false, isVisiblePrzychHead: true });
      }
    })
    .catch(error =>swal(error.response.data), this.setState({ isVisible3: true }));
}

  

  ChangeWniosek = (e) => {
  let { data2 } = this.state;
  data2 = data2.map(obj => {
    if (obj.IDA === e.target.id) {
      obj.Wniosek = e.target.value
    }
    return obj;
  });
  this.setState({
    data2
  });
}


  edit = (user) => {
    const nowyW = `${user.Wniosek}`.replace(',', '.');
    axios.put(apiurl+"api/Budzet?",
        {rok: this.state.rok, idUchwaly: this.state.id, idWydz: `${user.idWydzialu}`, idRodz: `${user.idRodzaju}`,
        dwbp: this.state.dwbp, dzial: `${user.Dzial}`,  rozdzial: `${user.Rozdzial}`, paragrafP4 : `${user.Paragraf}`+`${user.P4}`, pozycja: `${user.Pozycja}`, wniosek: nowyW },
        {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token} })
        .then(res => {
        console.log(res);
        swal("Zapisano zmiane!");
        })
        .catch((error) => swal(error.response.data));
      }


  wroc = () => {
    ReactDOM.render(<Lista  token = {this.state.token} username = {this.state.username} rok = {this.state.rok}/>, document.getElementById("root"));
  }

  dodaj = () => {
    ReactDOM.render(<Dodaj_plan token = {this.state.token} username = {this.state.username} rok = {this.state.rok} idUchwaly = {this.state.id} nazwauchwaly = {this.state.nazwauchwaly} />, document.getElementById("root"));
  }

  zmienhaslo = () => {
    ReactDOM.render(<ChangePswd token = {this.state.token} username = {this.state.username}  kodoper={this.state.kodoper} id={this.state.id} rok={this.state.rok} nazwastrony={this.state.nazwastrony}/>, document.getElementById("root"));
  }
  

  closeModal () {
    this.setState({ open: false })
  }
  closeModalUwagi () {
    this.setState({ openuwagi: false })
  }



  uwagi() {
    if(this.state.idw === "-1") { swal("Wybierz wydział") }
    else{ this.setState({openuwagi: true})}
  }

  koniecedycji() {
    if(this.state.idw === "-1") { swal("Wybierz wydział") }
    else{
      swal("Czy chcesz zamknąć edycję dla wydziału: " + this.state.nazwaw, {
        closeOnClickOutside: false,
        buttons: {
          cancel: "Anuluj",
          do: {
            text: "Zakończ edycje",
            value: "do",
          },
        },
      })
      .then((value) => {
        switch (value) {
          case "do":
            this.koniecedycjipost();
            break;
        }
      });
    }
  }

  koniecedycjipost() {
    axios.post(apiurl+"api/BudzetEdycja/ZamknijEdycje",
      {rok: this.state.rok, idUchwaly: this.state.id, idWydzialu: this.state.idw },
      {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
      .then(res => {
        swal(res.data);
      })
      .catch(error => swal(error.response.data));
  }

  sortowanieCyfry = (id) => {
    const { data2, posortowane } = this.state;
    if (posortowane === 'tak'){
    data2.sort((a, b) => a[id] - b[id]).reverse()
    this.setState({ data2, posortowane: '', reload: 'nie' })
    }
    else {
    data2.sort((a, b) => a[id] - b[id])    
    this.setState({ data2, posortowane: 'tak', reload: 'nie' })
    }
  }

  sortowanieNazwy = (id) => {
    const { data2, posortowane } = this.state;
    if (posortowane === 'tak'){
      data2.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )).reverse();
    this.setState({ data2, posortowane: '' })
    }
    else {
    data2.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )); 
    this.setState({ data2, posortowane: 'tak' })
    }  
  }


render() {


const { isLoading, data2, data3, data4, data6, dwbp, idw, nazwaw, username, nazwauchwaly, sprawdzam } = this.state;

return (
  
<React.Fragment>
<div class="container-fluid">
<h1>Uchwała - {nazwauchwaly}</h1>
<hr />
<div class="d-flex flex-wrap">
<div class="p-1">
<button class="btn btn-light" onClick={() => this.wroc()}> Wróć </button>
</div>
<div class="p-1">
    <select  type="select" class="form-control" onChange={this.onChangee.bind(this)} name="dwbp" value={dwbp}>
    <option key="1" defaultValue="Wybierz" hidden="hidden" >Wybierz</option>
    <option key="2" value="1" >Dochody budżetowe</option>
    <option key="3" value="2" >Wydatki budżetowe</option>
    <option key="4" value="3" >Dochody pozabudżetowe</option>
    <option key="5" value="4" >Wydatki pozabudżetowe</option>
    <option key="6" value="5" >Przychody</option>
    <option key="7" value="6" >Rozchody</option>

  </select>
</div>

<div class="p-1">
<select type="select" class="form-control" onChange={this.onChangeeee.bind(this)} value={idw}>
<option key="-1" value="-1">Wszystkie wydziały</option>
{!isLoading ? (
  data3.map(user2 => {
  const { idw, nazwaw} = user2;
    return (
    <option key={idw} value={idw}>{nazwaw}</option>
    );
    })
  ) : ( null )}
</select>   
</div>

<div class="p-1">
<button class="btn btn-light" onClick={() => this.dodaj()}> Dodaj </button>
</div>
<div class="p-1">
<button class="btn btn-light" onClick={() => this.onChangeUkryj()}> Pokaż / Ukryj zadania </button>
</div>
<div class="p-1">
<button class="btn btn-light" onClick={() => this.odswiez()}> Odśwież </button>
</div>
<div class="p-1">
<button class="btn btn-light" onClick={() => this.koniecedycji()}> Zakończ edycję </button>
</div>
<div class="p-1">
<button class="btn btn-light" onClick={() => this.uwagi()}> Uwagi </button>
</div>
</div>
<br />
<div class="table-responsive-xl card">
<table id="Tabela" class="table table-hover table-sm">   
<thead>
{ this.state.isVisible ? (
  <tr >
    <th scope="col" class="text-center point" onClick={() => this.sortowanieCyfry('Dzial')}><b>Dział</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieCyfry('Rozdzial')}><b>Rozdział</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieCyfry('Paragraf')}><b>Paragraf</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieCyfry('Pozycja')}><b>Pozycja</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieNazwy('Rodzaj')}><b>Rodzaj</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieNazwy('Wydzial')}><b>Wydział</b></th>
    <th scope="col" class="text-center point" onClick={() => this.sortowanieNazwy('Opis')}><b>Opis</b></th>
    <th scope="col" class="text-center col-xs-4 col-sm-4 col-md-2 col-lg-2 point" onClick={() => this.sortowanieCyfry('Przed')}><b>Przed</b></th>
    <th scope="col" class="text-center col-xs-4 col-sm-4 col-md-2 col-lg-2 point" onClick={() => this.sortowanieCyfry('Po')}><b>Po</b></th>
    <th scope="col" class="text-center col-xs-4 col-sm-4 col-md-2 col-lg-2 point" onClick={() => this.sortowanieCyfry('poWniosku')}><b>Po Wniosku</b></th>    
    <th scope="col" class="text-center col-xs-4 col-sm-4 col-md-2 col-lg-2 point" onClick={() => this.sortowanieCyfry('zmiany')}><b>Zmiany</b></th>    
    <th scope="col" class="text-center col-xs-4 col-sm-4 col-md-2 col-lg-2 point" onClick={() => this.sortowanieCyfry('Wniosek')}><b>Wniosek</b></th>
    <th scope="col" class="text-center"></th>
  </tr>

) : null }

{ this.state.isVisiblePrzychHead ? (
  <tr >
    <th scope="col" class="text-center"><b>Paragraf</b></th>
    <th scope="col" class="text-center"><b>Pozycja</b></th>
    <th scope="col" class="text-center"><b>Wydzial</b></th>
    <th scope="col" class="text-center"><b>Opis</b></th>
    <th scope="col" class="text-center"><b>Wniosek</b></th>
    <th scope="col" class="text-center"><b>Przed</b></th>
    <th scope="col" class="text-center"><b>Zmiany</b></th>
    <th scope="col" class="text-center"><b>Po</b></th>
    <th scope="col" class="text-center"><b>Po wniosku </b></th>    
  </tr>
) : null }
</thead>
<tbody>

{ this.state.isLoadingdane ? (
  data2.map(user => {
  const { IDA, Rozdzial, Dzial, Paragraf, idRodzaju, Rodzaj, Wydzial, Opis, Przed, Po, P4, Wniosek, Pozycja, idWydzialu, poWniosku, zmiany, uchWydzStat, uchWydzStatZad} = user;
  const { Ukryta, rok, id, dwbp, token, renderZadania, pobrano, reload } = this.state;
  return (
    <React.Fragment key={IDA}>
     <tr>
        <td ><p class="text-right">{Dzial}</p></td>
        <td ><p class="text-right">{Rozdzial}</p></td>
        <td ><p class="text-right">{Paragraf}{P4}</p></td>
        <td ><p class="text-right">{Pozycja}</p></td>
        <td ><p>{Rodzaj}</p></td>
        <td ><p>{Wydzial}</p></td>
        <td ><p>{Opis}</p></td>
        <td ><p class="text-right"><NumberFormat value={Przed} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
        <td ><p class="text-right"><NumberFormat value={Po} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td> 
        <td ><p class="text-right"><NumberFormat value={poWniosku} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
        <td ><p class="text-right"><NumberFormat value={zmiany} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>   
        <td class="text-right">
        {uchWydzStat === 'true' ?(
        <NumberFormat value={Wniosek} class="form-control text-right" id={IDA} onChange={(e) => this.ChangeWniosek(e)} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} />
        ) :(<NumberFormat value={Wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} />)
        }
        </td>
        <td >
        {uchWydzStat === 'true' ?(
        <>
        <button  onClick={() => this.edit(user)} type="button" class="btn btn-light button-text">Zapisz</button>
        <br /> <br />
        <button class="btn btn-light button-text" onClick={() => this.setState({ idWydzialupop: `${user.idWydzialu}`, idRodzpop: `${user.idRodzaju}`, dzialpop: `${user.Dzial}`, rozdzialpop: `${user.Rozdzial}`, pragrafp4pop: `${user.Paragraf}`+`${user.P4}`, pozycjapop: `${user.Pozycja}`, open: true, reload: "nie", formularzpop: true })}> Zadania </button>
        </>
        ) :( 
        <button class="btn btn-light button-text" onClick={() => this.setState({ idWydzialupop: `${user.idWydzialu}`, idRodzpop: `${user.idRodzaju}`, dzialpop: `${user.Dzial}`, rozdzialpop: `${user.Rozdzial}`, pragrafp4pop: `${user.Paragraf}`+`${user.P4}`, pozycjapop: `${user.Pozycja}`, open: true, reload: "nie", formularzpop: true })}> Zadania </button>
        )
        }
        
        </td>
      </tr>  
    <tr class={Ukryta} key={IDA} >
    <td colSpan="12" className="bezramy">
    <Zadanie apiurl={this.props.apiurl} rok = {rok} token = {token} id = {id} dwbp = {dwbp} dzial = {Dzial} rozdzial = {Rozdzial} paragraf = {Paragraf}  p4 = {P4} pozycja = {Pozycja} idWydz = {idWydzialu} idRodz = {idRodzaju} renderZadania={renderZadania} pobrano={pobrano} reload={reload} IDA = {IDA}/>
    </td>
    <td colSpan="1" className="bezramy">
    {uchWydzStatZad === 'true' ? (<button class="btn btn-light button-text" onClick={() => this.setState({ idWydzialupop: `${user.idWydzialu}`, idRodzpop: `${user.idRodzaju}`, dzialpop: `${user.Dzial}`, rozdzialpop: `${user.Rozdzial}`, pragrafp4pop: `${user.Paragraf}`+`${user.P4}`, pozycjapop: `${user.Pozycja}`, open: true, reload: "nie", formularzpop: false })}> Dodaj zadanie </button> ) : null }
    </td>
    </tr>
    </React.Fragment>
      );
    })
    
    ) : null }

{ this.state.isVisible2 ? ( <tr ><td colSpan="13"><center><p>Brak danych</p></center></td></tr> ) : null }
{ this.state.isVisible3 ? ( <tr ><td colSpan="13"><center><p>Wybierz wartości</p></center></td></tr> ) : null }

{ this.state.isLoadingPrzychody ? (
  data6.map(user6 => {
  const { IDP, Paragraf, Pozycja, Wydzial, Opis, Wniosek, Przed, Zmiany, Po, poWniosku } = user6;
  return (
    <>
     <tr id={IDP}>
        <td ><p>{Paragraf}</p></td>
        <td ><p>{Pozycja}</p></td>
        <td ><p>{Wydzial}</p></td>
        <td ><p>{Opis}</p></td>
        <td ><p><NumberFormat value={Wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
        <td ><p><NumberFormat value={Przed} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
        <td ><p><NumberFormat value={Zmiany} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
        <td ><p><NumberFormat value={Po} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
        <td ><p><NumberFormat value={poWniosku} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
      </tr>  
    </>
      );
    })
    
    ) : null }

    </tbody>
    </table>
    </div>
<Popup open={this.state.open} closeOnDocumentClick onClose={this.closeModal}>
<div class="okno">
<a class="close point" onClick={this.closeModal}>
&times;
</a>
<Zadanie_Pop apiurl={this.props.apiurl} token={this.state.token} rok= {this.state.rok} idUchwaly= {this.state.id} dzial = {this.state.dzialpop} rozdzial = {this.state.rozdzialpop} paragrafP4 = {this.state.pragrafp4pop} pozycja = {this.state.pozycjapop} dwbp = {this.state.dwbp} idWydz = {this.state.idWydzialupop} idRodz = {this.state.idRodzpop} formularzpop = {this.state.formularzpop}/>
</div>
</Popup>
<Popup open={this.state.openuwagi} closeOnDocumentClick onClose={this.closeModalUwagi}>
<div class="okno">
<a class="close point" onClick={this.closeModalUwagi}>
&times;
</a>
<Uwagi_Pop apiurl={this.props.apiurl} token={this.state.token} rok= {this.state.rok} idUchwaly= {this.state.id} idWydzial= {this.state.idw} nazwaw = {this.state.nazwaw} />
</div>
</Popup>
</div>
</React.Fragment>
    );
  }
}

