import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from './NavBar';
import axios from 'axios';
import swal from 'sweetalert';
import NumberFormat from 'react-number-format';
import './index.css';
import apiurl from './apiurl';
import Download from './export_zestawienia_exel.js';
import Zestawieniahtml from './Zestawienia_html.js';
import Print from './print_zestawienie.js';
import Gen_zestawienie from './Gen_zestawienie';

export default class Pokaz_zestawienie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    token: props.token ,
    username: props.username ,
    id: props.id,      
    rok: props.rok,  
    nazwastrony: 'Pokaz_zestawienie',
    grupaZestawienValue : props.grupaZestawienValue,
    zestawienieValue : props.zestawienieValue,
    rodzajValues : props.rodzajValues,
    wydzialyValues : props.wydzialyValues,
    zZadaniami : props.zZadaniami,
    pominStanyZerowe : props.pominStanyZerowe,
    pokazdane: false,
    tableorhtml: false,
    pobieranie: true,
    data: [],
    zadania: [],
  }
  this.pobierz();
}

componentWillMount(){
  ReactDOM.render(<NavBar token = {this.state.token} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} username = {this.state.username} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
}
  sortowanieCyfry = (id) => {
    const { data, posortowane } = this.state;
    if (posortowane === 'tak'){
    data.sort((a, b) => a[id] - b[id]).reverse()
    this.setState({ data, posortowane: ''  })
    }
    else {
    data.sort((a, b) => a[id] - b[id])    
    this.setState({ data, posortowane: 'tak' })
    }
  }

  sortowanieNazwy = (id) => {
    const { data, posortowane } = this.state;
    if (posortowane === 'tak'){
      data.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )).reverse();
    this.setState({ data, posortowane: '' })
    }
    else {
    data.sort((a, b) => a[id].localeCompare(b[id], 'pl', { sensitivity: 'base' } )); 
    this.setState({ data, posortowane: 'tak' })
    }  
  }

pobierz = () => {
  axios.post(apiurl+"api/Raporty?",
      {rok : this.state.rok , grupaZestawienValue : this.state.grupaZestawienValue , zestawienieValue : this.state.zestawienieValue, rodzajValues : this.state.rodzajValues, wydzialyValues : this.state.wydzialyValues, idUchwaly : this.state.id, zZadaniami : this.state.zZadaniami, pominStanyZerowe : this.state.pominStanyZerowe },
      {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
      .then(res => {
        if (this.state.zestawienieValue === "1") {
          this.setState({ data : res.data,  pokazdane: true, tableorhtml: true, pobieranie: false });
          ReactDOM.render(<Download data={res.data.dane} />, document.getElementById("download"));
        } 
        else if (this.state.zestawienieValue === "2") {
          this.setState({ tableorhtml: false, pobieranie: false });
          ReactDOM.render(<Zestawieniahtml text1={this.props.text1} data={res.data} />, document.getElementById("html"));
          ReactDOM.render(<Print data={res.data} />, document.getElementById("print"));
        }
        else {
        swal("Błąd danych.") 
        }
        
      })
  .catch(error => swal(error.response.data) );
}

wroc(){
  ReactDOM.render(<Gen_zestawienie token = {this.state.token} username = {this.state.username}  kodoper={this.state.kodoper} nazwastrony={this.state.nazwastrony} id = {this.state.id} rok={this.state.rok}/>, document.getElementById("root"))
}


render() {
const { pokazdane, data, tableorhtml, pobieranie} = this.state;

return (
<React.Fragment>
<div class="container-fluid">
<h3>Zestawienie</h3>
<hr />
{pobieranie ? (<div><center><h4>Pobieranie danych...</h4></center></div>) : null }
{tableorhtml ? (
<> 
<div><button type="button"  class="btn btn-light float-left"  onClick={() => ReactDOM.render(<Gen_zestawienie token = {this.state.token} username = {this.state.username}  kodoper={this.state.kodoper} nazwastrony={this.state.nazwastrony} id = {this.state.id} rok={this.state.rok}/>, document.getElementById("root"))} id="function1" >Wróć</button></div>
<div id="download" className="float-right"></div>
<br></br><br></br><br></br>
<div class="table-responsive-xl card">
<table id="Tabela" class="table table-hover table-sm">   
<thead >
<tr >
    <th scope="col" class="text-center" onClick={() => this.sortowanieCyfry('dzial')}><b>Dział</b></th>
    <th scope="col" class="text-center" onClick={() => this.sortowanieCyfry('rozdzial')}><b>Rozdział</b></th>
    <th scope="col" class="text-center" onClick={() => this.sortowanieCyfry('paragraf')}><b>Paragraf</b></th>
    <th scope="col" class="text-center" onClick={() => this.sortowanieCyfry('pozycja')}><b>Pozycja</b></th>
    <th scope="col" class="text-center" onClick={() => this.sortowanieNazwy('rodzaj')}><b>Rodzaj</b></th>
    <th scope="col" class="text-center" onClick={() => this.sortowanieNazwy('wydzial')}><b>Wydział</b></th>
    <th scope="col" class="text-center" onClick={() => this.sortowanieNazwy('nazwa')}><b>Nazwa</b></th>
    <th scope="col" class="text-center col-md-2" onClick={() => this.sortowanieCyfry('poczatkowy')}><b>Plan początkowy</b></th>
    <th scope="col" class="text-center col-md-2" onClick={() => this.sortowanieCyfry('przed')}><b>Przed zmianą</b></th>
    <th scope="col" class="text-center col-md-2" onClick={() => this.sortowanieCyfry('zmniejszenia')}><b>Zmniejszenia</b></th>    
    <th scope="col" class="text-center col-md-2" onClick={() => this.sortowanieCyfry('zwiekszenia')}><b>Zwiększenia</b></th>    
    <th scope="col" class="text-center col-md-2" onClick={() => this.sortowanieCyfry('po')}><b>Po zmianach</b></th>
    <th scope="col" class="text-center" onClick={() => this.sortowanieCyfry('wniosek')}><b>Wniosek</b></th>
</tr>
</thead>
<tbody>
{ pokazdane ? (
  data.dane.map(user => {
  const { rozdzial, dzial, paragraf, rodzaj, wydzial, nazwa, poczatkowy, przed,  pozycja, zmniejszenia, zwiekszenia, po, wniosek, zadania} = user;
  return (    
  <React.Fragment>
    <tr>
      <td ><p>{dzial}</p></td>
      <td ><p>{rozdzial}</p></td>
      <td ><p>{paragraf}</p></td>
      <td ><p>{pozycja}</p></td>
      <td ><p>{rodzaj}</p></td>
      <td ><p>{wydzial}</p></td>
      <td ><p>{nazwa}</p></td>
      <td ><p><NumberFormat value={poczatkowy} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
      <td ><p><NumberFormat value={przed} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td> 
      <td ><p><NumberFormat value={zmniejszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
      <td ><p><NumberFormat value={zwiekszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>   
      <td ><NumberFormat value={po} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></td>
      <td ><NumberFormat value={wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></td>
    </tr>
    {zadania !== null ? (<>
    {zadania.length > 0 ? ( <tr><td colSpan="13">
    <center><h4>Zadania</h4></center>
    <table class="table table-hover table-sm">
    <thead>
    <tr >
    <th scope="col" class="text-center" ><b>Nazwa</b></th>
    <th scope="col" class="text-center" ><b>Początkowy</b></th>
    <th scope="col" class="text-center" ><b>Przed</b></th>
    <th scope="col" class="text-center" ><b>Zmniejszenia</b></th>
    <th scope="col" class="text-center" ><b>Zwiększenia</b></th>
    <th scope="col" class="text-center" ><b>Po</b></th>
    </tr>
    </thead>
    <tbody>
    {zadania.map(elem => {
    const { nazwa, poczatkowy, przed, zmniejszenia, zwiekszenia, po } = elem;
    return (
    <tr>
      <td>{nazwa}</td>
      <td>{poczatkowy}</td>
      <td>{przed}</td>
      <td>{zmniejszenia}</td>
      <td>{zwiekszenia}</td>
      <td>{po}</td>
    </tr>
    );
    })
    }
    </tbody>
    </table>
    </td></tr> ) : null } </>) : null }
  </React.Fragment>
    );
  })
  ) : (<tr ><td colSpan="13"><center><p>Brak danych</p></center></td></tr>) }
</tbody>
{pokazdane ? (
<tfoot >
<tr>
  <td colSpan="7" rowspan="2"><div ><p><b>SUMA</b></p></div></td>
  <td><p><b>Początkowy</b></p></td>
  <td><p><b>Przed</b></p></td>
  <td><p><b>Zmniejszenia</b></p></td>
  <td><p><b>Zwiększenia</b></p></td>
  <td><p><b>Po</b></p></td>
  <td><p><b>Wniosek</b></p></td>
</tr>
<tr>
  <td><p><NumberFormat value={data.poczatkowy} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
  <td><p><NumberFormat value={data.przed} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
  <td><p><NumberFormat value={data.zmniejszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
  <td><p><NumberFormat value={data.zwiekszenia} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
  <td><p><NumberFormat value={data.po} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
  <td><p><NumberFormat value={data.wniosek} thousandSeparator={' '} displayType={'text'} fixedDecimalScale={true} decimalScale={2} decimalSeparator={','} /></p></td>
</tr>
</tfoot>) : null }
</table>
</div>
</>): (
<div class="container-fluid">
<div className="float-left"><button type="button"  class="btn btn-light pull-left"  onClick={() => ReactDOM.render(<Gen_zestawienie token = {this.state.token} username = {this.state.username}  kodoper={this.state.kodoper} nazwastrony={this.state.nazwastrony} id = {this.state.id} rok={this.state.rok}/>, document.getElementById("root"))} id="function1" >Wróć</button></div>
<div id="print" className="float-right"></div>
<br></br><br></br>
<br></br>
<div id="html" class="container overf"></div>
</div>
)}

</div>
</React.Fragment>
    );
  }
}

