import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import NavBar from './NavBar';
import App from './App';
import swal from 'sweetalert';
import axios from 'axios';
import Select from 'react-select';
import './index.css';
import apiurl from './apiurl';


export default class Dodaj_plan extends React.Component {
    constructor(props) {
      super(props);    
      
      this.state = {
      errors: null, 
      token: props.token ,
      username: props.username ,
      idUchwaly : props.idUchwaly,
      rok: props.rok ,
      nazwastrony: 'Dodaj_plan', 
      dwbp: '1',
      Dzial: '',
      pozycjavalue: 'Inna',
      isLoadingPozycja: true,
      isLoadingWydzial: true,
      isLoadingRodzaj: true,
      showPozycja: false,
      data18: [],
    }
  }

componentWillMount(){
  ReactDOM.render(<NavBar apiurl={this.props.apiurl} token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
}

componentDidMount() { 
  this.onListaDzial();
  this.onListaRozdzial();
  this.onListaParagraf();
  this.onListaP4();
  this.onListaPozycja();
  this.onListaWydzialy();
  this.onListaRodzaj();
}

onChangee = (z) => {
    this.setState({ [z.target.name]: z.target.value }, () => { this.onListaParagraf(); this.onListaPozycja() });
}

onChange = (c) => {
  this.setState({ [c.target.name]: c.target.value });
}

onChangePozycja = (p) => {
  this.setState({ pozycjavalue: p.target.value }, () => { this.showP() });
}

showP = () => {
  if(this.state.pozycjavalue==='Inna') {
    this.setState({ showPozycja: false });
  }
  else
  {
    this.setState({ showPozycja: true });
  }
}

onListaDzial = async () => {
  const res = await axios.get(apiurl+"api/Listy/GetSlownikDzialy?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data11 = res.data.map( category => ({ value: category.value, label: category.text}));
  this.setState({data11, Dzial: data11[0].value, Dzialt: data11[0].label});
}

onListaRozdzial = async () => {
  const res = await axios.get(apiurl+"api/Listy/GetSlownikRozdzialy?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data12 = res.data.map( category => ({ value: category.value, label: category.text}));
  this.setState({data12, Rozdzial: data12[0].value, Rozdzialt: data12[0].label});
}

onListaParagraf = async () => {
  const res = await axios.get(apiurl+"api/Listy/GetSlownikParagrafy?", { params : {rok : this.state.rok, dwprbp : this.state.dwbp }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data13 = res.data.map( category => ({ value: category.value, label: category.text}));
  this.setState({data13, Paragraf: data13[0].value, Paragraft: data13[0].label});
}

onListaP4 = async () => {
  const res = await axios.get(apiurl+"api/Listy/GetSlownikP4?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data14 = res.data.map( category => ({ value: category.value, label: category.text}));
  this.setState({data14, P4: data14[0].value, P4t: data14[0].label});
}

onListaPozycja = async () => {
  const res = await axios.get(apiurl+"api/Listy/GetSlownikPozycje?", { params : {rok : this.state.rok, dwrpbp : this.state.dwbp }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data15 = res.data.map( category => ({ pozycjavalue: category.value, nazwapozycja: category.text}));
  this.setState({data15, isLoadingPozycja: false, pozycjavalue: data15[0].pozycjavalue});
}

onListaWydzialy = async () => {
  const res = await axios.get(apiurl+"api/Listy/GetWydzialy?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data16 = res.data.map( category => ({ wydzialvalue: category.value, nazwawydzial: category.text}));
  this.setState({data16, isLoadingWydzial: false, wydzialvalue: data16[0].wydzialvalue});
}

onListaRodzaj = async () => {
  const res = await axios.get(apiurl+"api/Listy/GetSlownikRodzaje?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => this.setState({ error,  }));
  let data17 = res.data.map( category => ({ rodzajvalue: category.value, nazwarodzaj: category.text}));
  this.setState({data17, isLoadingRodzaj: false, rodzajvalue: data17[0].rodzajvalue});
}


Zapisz = (zapisz) => {
  zapisz.preventDefault();
  axios.post(apiurl+"api/Budzet?",
      {rok : this.state.rok , idUchwaly : this.state.idUchwaly , dzial : this.state.Dzial , rozdzial : this.state.Rozdzial , paragraf : this.state.Paragraf , p4 : this.state.P4 ,
      pozycja : this.state.pozycjavalue , idWydzialu : this.state.wydzialvalue , idRodzaju : this.state.rodzajvalue , dwpr : this.state.dwbp , opis : this.state.Nazwa , kwota : this.state.Kwota},
      {headers : {'Authorization' : this.state.token } })
      .then(res => {
        swal("Dodano!");
        ReactDOM.render(<App token = {this.state.token} username = {this.state.username} id={this.state.idUchwaly} rok={this.state.rok} nazwauchwaly = {this.props.nazwauchwaly}/>, document.getElementById("root"));
      })
  .catch(error => swal(error.response.data));
}

wroc = () => {
  ReactDOM.render(<App  token = {this.state.token} username = {this.state.username} id={this.state.idUchwaly} rok={this.state.rok} nazwauchwaly = {this.props.nazwauchwaly}/>, document.getElementById("root"));
}

render() {
const { data11, data12, data13, data14, data15, data16, data17, dwbp, Dzialt, Rozdzialt, Paragraft, P4t, pozycjavalue, wydzialvalue, rodzajvalue, isLoadingPozycja, isLoadingWydzial, isLoadingRodzaj, showPozycja } = this.state;
  
  return (
   
  <React.Fragment>
<div class="container-fluid">
<h1>Nowy Plan</h1>
    <br></br>
    <form id="function" name="form" class="form-horizontal" onSubmit={(zapisz) => this.Zapisz(zapisz)} > 
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label class="control-label">Wybierz:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <select  type="select" class="form-control" onChange={this.onChangee.bind(this)} name="dwbp" value={dwbp}>
                  <option key="1" defaultValue="Wybierz" hidden="hidden" >Wybierz</option>
                  <option key="2" value="1" >Dochody budżetowe</option>
                  <option key="3" value="2" >Wydatki budżetowe</option>
                  <option key="4" value="3" >Dochody pozabudzetowe</option>
                  <option key="5" value="4" >Wydatki pozabudzetowe</option>
                  <option key="6" value="5" >Przychody</option>
                  <option key="7" value="6" >Rozchody</option>
                  </select>
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Działy:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  { dwbp === "5" || dwbp === "6" ?(<Select name="form-field-name" isDisabled={true} options={data11} placeholder={Dzialt} onChange={d => this.setState({Dzial: d.value})} />) : (<Select name="form-field-name" options={data11} placeholder={Dzialt} onChange={d => this.setState({Dzial: d.value})} />)}
                  </div>
          </div> <br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Rozdziały:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  { dwbp === "5" || dwbp === "6" ?(<Select name="form-field-name" isDisabled={true} options={data12} placeholder={Rozdzialt} onChange={r => this.setState({Rozdzial: r.value})}/>) :(<Select name="form-field-name" options={data12} placeholder={Rozdzialt} onChange={r => this.setState({Rozdzial: r.value})}/>)}
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Paragraf:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <Select name="form-field-name" options={data13} placeholder={Paragraft} onChange={p => this.setState({Paragraf: p.value})}/>
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >P4:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  { dwbp === "5" || dwbp === "6" ?(<Select name="form-field-name" isDisabled={true} options={data14} placeholder={P4t} onChange={b => this.setState({P4: b.value})}/>) : (<Select name="form-field-name" options={data14} placeholder={P4t} onChange={b => this.setState({P4: b.value})}/>)}
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Pozycja:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <select type="select" class="form-control" name="pozycjavalue" value={pozycjavalue} onChange={this.onChangePozycja.bind(this)} >
                  {!isLoadingPozycja ? (
                  data15.map(user15 => {
                  const {pozycjavalue, nazwapozycja} = user15;
                  return (
                  <option value={pozycjavalue} > {nazwapozycja}</option>
                  );
                  })
                  ) : ( <option>Wybierz</option> )}
                  </select>
                  </div>
          </div><br></br>
          <div class="row">
          {!showPozycja ? (<>
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Pozycja:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <input type="text" class="form-control" pattern="[0-9]{4}" placeholder="Wprowadz inną pozycje..." required title="Minimum 4 cyfry" id="pozycjavalue" name="pozycjavalue"  onChange={this.onChange.bind(this)}></input>
                  </div>
          </>) : null }    
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Nazwa:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <input type="text" class="form-control" placeholder="Wprowadz opis..." id="Nazwa" name="Nazwa" onChange={this.onChange.bind(this)}></input>   
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Wydział:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <select type="select" class="form-control" name="wydzialvalue" value={wydzialvalue} onChange={this.onChange.bind(this)} >
                  {!isLoadingWydzial ? (
                  data16.map(user16 => {
                  const {wydzialvalue, nazwawydzial} = user16;
                  return (
                  <option value={wydzialvalue}> {nazwawydzial}</option>
                  );
                  })
                  ) : ( <option>Wybierz</option> )}
                  </select>
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Kwota:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  <input type="number" class="form-control"  required title="Podaj kwotę" placeholder="0,00" id="Kwota" name="Kwota" onChange={this.onChange.bind(this)}></input>   
                  </div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"><label >Rodzaj:</label></div>
                  <div class="col-xs-7 col-sm-7 col-md-5 col-lg-5">
                  { dwbp === "5" || dwbp === "6" ?(<select type="select" class="form-control" name="rodzajvalue" value={rodzajvalue} onChange={this.onChange.bind(this)} disabled >
                  {!isLoadingRodzaj ? (
                  data17.map(user17 => {
                  const {rodzajvalue, nazwarodzaj} = user17;
                  return (
                  <option value={rodzajvalue} > {nazwarodzaj}</option>
                  );
                  })
                  ) : ( <option>Wybierz</option> )}
                  </select>) : (<select type="select" class="form-control" name="rodzajvalue" value={rodzajvalue} onChange={this.onChange.bind(this)} >
                  {!isLoadingRodzaj ? (
                  data17.map(user17 => {
                  const {rodzajvalue, nazwarodzaj} = user17;
                  return (
                  <option value={rodzajvalue} > {nazwarodzaj}</option>
                  );
                  })
                  ) : ( <option>Wybierz</option> )}
                  </select>
                  )}
                  </div>
          </div><br></br>


          <div class="row">
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1"></div>
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1">
                  <button type="button"  class="btn btn-light flot-left"  onClick={() => this.wroc()} id="function1" >Wróć</button>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-1 col-lg-1">
                  <button type="submit"  class="btn btn-light float-right" id="function" >Zapisz</button>
                  </div>
          </div>
      </form>
      <hr />
  </div>
 
  </React.Fragment>
      );
    }
  }
