import React from "react";
import ReactDOM from 'react-dom';
import Select from 'react-select';
import axios from 'axios';
import swal from 'sweetalert';
import './index.css';
import apiurl from './apiurl';
import fileDownload from 'js-file-download';
import Lista from './Lista';



export default class Export_bestia extends React.Component {
constructor(props) {
    super(props);
    this.state = { 
    rok: props.rok,
    token: props.token,
    idUchwaly: props.idUchwaly,
    dataz: [],
    items: [],
    selectedOption: [],
    zadanie: 1,
    kod: ""
    }
    this.pobierzJednostki();
    this.pobierzdane = this.pobierzdane.bind(this);
}

pobierzJednostki = async () => {
    const res = await axios.get(apiurl+"api/Listy/GetWydzialy?", { params : {rok : this.state.rok }, headers: { 'Authorization' : this.state.token }}).catch(error => swal(error.data));
    let dataj = res.data.map( category => ({ value: category.value, label: category.text}));
    this.setState({dataj, idj: dataj[0].value, jednostka: dataj[0].label});
}

pobierzdane() {
    axios
        .get(apiurl+"api/ExportBestia?", 
        {params : {rok : this.state.rok, idWydzialu : this.state.idj },
        headers: { 'Authorization' : this.state.token }})
        .then(response => {
        this.setState({ 
            datap : response.data,
            nazwa : response.data.nazwa,
            symbolj : response.data.symbol,
            typ : response.data.typ,
            regon : response.data.regon,
            wk : response.data.wk,
            pk : response.data.pk,
            gk : response.data.gk,
            gt : response.data.gt,
            pt : response.data.pt, 
        })
        })
        .catch(error => { 
          swal(error.response.data);
        })
}

onChange(e) {
    this.setState({ [e.target.name]: e.target.value })
}

wybrane = (selectedOption) => { this.setState({ selectedOption }) };

polacz(zapisz) {
    let {selectedOption} = this.state;
    if(selectedOption.length >0){
    let {items, selectedOption} = this.state;
    selectedOption.map(item => { items.push(item.value) });
    this.pobierz(zapisz);
    } 
    else { swal("Wybierz wydziały.")}

}

pobierz = (zapisz) => {
    zapisz.preventDefault();
    axios.post(apiurl+"api/ExportBestia",
        {rok: this.state.rok, idUchwaly: this.state.idUchwaly, idWydzialu: this.state.idj, nazwa: this.state.nazwa,
        symbolJednostki: this.state.symbolj, typ: this.state.typ, regon: this.state.regon, wk: this.state.wk, pk: this.state.pk, gk: this.state.gk,
        gt: this.state.gt, pt: this.state.pt, kodZwiazkuJST: this.state.kod, wydzialyValues: this.state.items, zadaniaValue: this.state.zadanie },
        {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
        .then(res => {
        fileDownload(res.data, 'export_bestia.xml');
        })
    .catch(error => swal("Błąd!"));
}

wroc = () => {
ReactDOM.render(<Lista  token = {this.state.token} username = {this.state.username} rok = {this.state.rok}/>, document.getElementById("root"));
}

render() {
    let { dataj, dataz, zadanie, jednostka, nazwa, symbolj, typ, regon, wk, pk, gk, gt, pt, kod, selectedOption } = this.state
return (
<div className="container-fluid">
<h1>Export do systemu Bestia</h1>
<hr />
<form id="function" name="form" class="form-horizontal" > 
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">Jednostka:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <Select name="form-field-name" options={dataj} placeholder={jednostka} onChange={a => this.setState({idj: a.value})} />
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <center><button type="button" class="btn btn-light " onClick={this.pobierzdane} >Pobierz dane</button></center>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">Nazwa:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={nazwa} id="nazwa" name="nazwa"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div> <br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">Wydziały:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <Select name="form-field-name" placeholder ="Nic nie zaznaczono" options={dataj} isMulti={true} value={selectedOption} onChange={this.wybrane} closeMenuOnSelect={false} />
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">Zadania:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <Select name="form-field-name" options={dataz} placeholder="Brak zadań" />
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">SymbolJednostki:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={symbolj} id="symbolj" name="symbolj"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">Typ:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={typ} id="typ" name="typ"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">Regon:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={regon} id="regon" name="regon"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">WK:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={wk} id="wk" name="wk"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">PK:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={pk} id="pk" name="pk"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">GK:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={gk} id="gk" name="gk"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">GT:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={gt} id="gt" name="gt"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">PT:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={pt} id="pt" name="pt" onChange={(e) => this.onChange(e)}></input>
        </div>
</div><br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"><label class="control-label">KodZwiazkuJST:</label></div>
        <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">
        <input type="text" class="form-control" value={kod} id="kod" name="kod"  onChange={(e) => this.onChange(e)}></input>
        </div>
</div>         
<br></br>
<div class="row">
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1"></div>
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1">
        <button class="btn btn-light" onClick={() => this.wroc()}> Wróć </button>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-1 col-lg-1">
        <button type="submit"  class="btn  btn-light float-right" id="function" onClick={(zapisz) => this.polacz(zapisz)} >Pobierz</button>
        </div>
</div>
</form>
</div>   
);
}

}
