import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Lista from './Lista';
import axios from 'axios';
import swal from 'sweetalert';
import './index.css';
import NavBar from './NavBar';
import WRok from './WRok';
import apiurl from './apiurl';




export default class Logowanie extends React.Component {
    constructor() {
      super();    

      this.state = {
      errors: null, 
      token: 'brak',
      nazwastrony: 'Logowanie',
      check: false,
      logowanie: true,
      };
      this.checkboxvalue = this.checkboxvalue.bind(this);
    }
  
    componentDidMount() {
    if(sessionStorage.length !== 0){
    ReactDOM.render(<WRok rok="2019" token = {sessionStorage.getItem("token")} username = {sessionStorage.getItem("username")} kodoper = {sessionStorage.getItem("koD_OPER")} nazwastrony={this.state.nazwastrony}/>, document.getElementById("root"));
    } 
    else {
      if(localStorage.getItem("token") === null){ this.setState({ token: 'brak' }) } 
      else { this.checktoken() }
    }
    }

    checktoken() {
      axios.post(apiurl+"api/auth/CheckToken",
          {headers : {'Content-Type': 'application/json' , 'Authorization' : localStorage.getItem("token") } })
          .then(res => {
          ReactDOM.render(<WRok rok="2019" token = {localStorage.getItem("token")} username = {localStorage.getItem("username")} kodoper = {localStorage.getItem("koD_OPER")} nazwastrony={this.state.nazwastrony}/>, document.getElementById("root"));
          })
      .catch(error => {
        this.setState({ token: 'brak' });
        localStorage.clear();
        sessionStorage.clear();
        this.render()
      });
    }
    
    componentWillMount(){
      ReactDOM.render(<NavBar token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
    }
   
    onChange = (e) => {
    
      this.setState({  [e.target.name]: e.target.value });
    }

    checkboxvalue() {this.setState(state => ({ check: !state.check }))}

  
    logowanie = (z) => {
      this.setState({logowanie: false});
      z.preventDefault();
      axios.post(apiurl+"api/Auth/Login",
          {Username : this.state.username , Password : this.state.password },
          {headers : {'Content-Type': 'application/json'} })
          .then(res => {
            const dzh = res.data.dzh;
            this.haslo(dzh);
            if(this.state.check) {
            localStorage.setItem('token', res.data.token);
            localStorage.setItem('username', res.data.nazwa);
            localStorage.setItem('koD_OPER', res.data.koD_OPER);
            ReactDOM.render(<WRok rok="2019" token = {res.data.token} username = {res.data.nazwa} kodoper = {res.data.koD_OPER} nazwastrony={this.state.nazwastrony}/>, document.getElementById("root"));
            }else {
            sessionStorage.setItem('token', res.data.token);
            sessionStorage.setItem('username', res.data.nazwa);
            sessionStorage.setItem('koD_OPER', res.data.koD_OPER);
            ReactDOM.render(<WRok rok="2019" token = {res.data.token} username = {res.data.nazwa} kodoper = {res.data.koD_OPER} nazwastrony={this.state.nazwastrony}/>, document.getElementById("root"));
            }
          })
      .catch(error => {
        if(error.response) {
          if(error.response.status === 400) {
            swal(error.response.data);
            this.setState({logowanie: true })
          } else if(error.response.status === 403) {
            swal(error.response.data);
            this.setState({logowanie: true })
          } 
        } else {
          swal("Wystąpił nieoczekiwany błąd, spróbuj ponownie później");
          this.setState({logowanie: true })

        }
        
      });
    }

  haslo(dzh) {
  var moment = require('moment');
  var data = moment(dzh).format('YYYY-MM-DD HH:mm:ss')
  var now = new Date();
  var dni = moment(data).diff(now, 'days')
  if( dni < -30) {
    swal("Hasło wygasło!")
  } else if ( dni === -30 ) {
    swal("Hasło wygasa dziś!")
  } else if ( dni === -29 ) {
    swal("Hasło wygaśnie za 1 dzień!")
  } else if ( dni === -28 ) {
    swal("Hasło wygaśnie za 2 dni!")
  } else if ( dni === -27 ) {
    swal("Hasło wygaśnie za 3 dni!")
  } else if ( dni === -26 ) {
    swal("Hasło wygaśnie za 4 dni!")
  }
  }

  render() {
  return (
  <React.Fragment>
  <div className="container-fluid">
      <h1>Logowanie</h1>
      <br></br><br></br>
      <form id="function" name="form" class="form-horizontal" onSubmit={(z) => this.logowanie(z)}> 
        <div className="row">
                <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Login:</label></div>
                <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><input type="text" value={this.state.username} class="form-control"  id="username" name="username" placeholder="Uzytkownik" onChange={this.onChange.bind(this)} required></input></div>
        </div><br></br>
        <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Hasło:</label></div>
                <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><input type="password" class="form-control" value={this.state.password}  id="password" name="password" placeholder="Haslo" onChange={this.onChange.bind(this)} required></input></div>
        </div><br></br>
        <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Zapamiętaj mnie:</label></div>
            <div class="col-xs-5 col-sm-5 col-md-2 col-lg-2 ">
            <input type="checkbox" className="float-right" onClick={this.checkboxvalue} ></input>
            </div>   
        </div>
        <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"></div>
            <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
            {this.state.logowanie ? (<button type="submit"  class="btn btn-primary float-right" id="function" >Zaloguj</button>) : (<button type="submit"  class="btn btn-primary float-right" id="function" disabled >Zaloguj</button>) }
            </div>   
        </div>
      </form>
  </div>
  </React.Fragment>
  );
  }
  }
