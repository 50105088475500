import React, { Component } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import './index.css';
import apiurl from './apiurl';


export default class Uwagi_Pop extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        errors: null, 
        token: props.token ,
        rok: props.rok ,
        idUchwaly: props.idUchwaly ,
        idWydzial: props.idWydzial ,
        nazwaw: props.nazwaw ,
        data: [],
      };
      this.getData();
    }
    
    onChange = (z) => { 
    this.setState({ comment: z.target.value }) 
    }
    
    getData = () => {
      axios
        .get(apiurl+"api/UwagiWniosku?", 
        {params : {rok: this.state.rok, idUchwaly: this.state.idUchwaly, idWydzial: this.state.idWydzial },
        headers: { 'Authorization' : this.state.token }})
        .then(response => {
          if(response.data.comment === '' || response.data.comment === null) {this.setState({ comment: null })} 
          else {this.setState({ comment: response.data.comment })}
        })
        .catch(error => { 
          swal(error.response.data);
        })
    }

    zapiszuwagi = (u) => {
      u.preventDefault();
      axios.post(apiurl+"api/UwagiWniosku",
          {rok: this.state.rok, idUchwaly: this.state.idUchwaly, idWydzial: this.state.idWydzial, comment: this.state.comment },
          {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
          .then(res => {
            swal("Zapisano!");
          })
      .catch(error => swal(error.response.data));
    }
    
  
render() {
const { comment, nazwaw } = this.state;
return (
<React.Fragment>
<div className="container">
  <h3>Uwagi</h3>
  <p>Dodaj uwagę do wniosku dla wydziału {nazwaw}</p>
  <hr width="75%" align="left"/>
  <div class="row">
  <textarea type="text" class="form-control" rows="3" value={comment} placeholder="Wprowadź uwagi do wnioseku..."  onChange={(z) => this.onChange(z)}></textarea>
  </div>
  <br />
  <div class="row">
  <button  onClick={(u) => this.zapiszuwagi(u)} type="button" class="btn btn-light float-right m-2" >Zapisz</button>
  </div>
</div>
</React.Fragment>
      );
    }
  }
