import React from 'react';
import ReactDOM from 'react-dom';
import NavBar from './NavBar';
import './index.css';
import Wydzialy from './Wydzialy';
import Lista from './Lista';
import Dodaj_plan from './Dodaj_plan';
import Lista_zestawienia from './Lista_zestawienia';
import apiurl from './apiurl';
import axios from 'axios';
import swal from 'sweetalert';


export default class Rok extends React.Component {
  constructor(props) {
    super(props); 
    
    this.state = {
    token: props.token ,
    username: props.username ,
    kodoper: props.kodoper,
    rok: props.rok,
    id: props.id,
    nazwastrony: props.nazwastrony,
    lata: [],
    };

    this.lata();
  }

  componentWillMount(){
    ReactDOM.render(<NavBar  token = {this.state.token} username = {this.state.username} id = {this.state.id} rok = {this.state.rok} nazwastrony = {this.state.nazwastrony} kodoper = {this.state.kodoper} />, document.getElementById("header")); 
    if(this.state.nazwastrony === "Logowanie") {this.setState({ nazwastrony: "Rok"})}
  }

  onChange = (e) => {
  this.setState({ [e.target.name]: e.target.value });
  }


    lata() {
  axios.get(apiurl+"api/Listy/getYears",
    {headers : {'Content-Type': 'application/json' , 'Authorization' : this.state.token } })
    .then(res => {
      this.setState({ lata : res.data })
    })
    .catch(error => swal(error.response.data));
}

  zmianaroku = () => {
    const Componenty = {
      "Lista": Lista,
      "App": Lista,
      "Dodaj_plan": Dodaj_plan,
      "Wydzialy": Wydzialy,
      "Edytuj_wydzial": Wydzialy,
      "Dodaj_wydzial": Wydzialy,
      "Lista_zestawienia": Lista_zestawienia,
      "Gen_zestawienie": Lista_zestawienia,
      "Pokaz_zestawienie": Lista_zestawienia,
      "Rok": Lista,
    }
    let name = this.state.nazwastrony;
    let TagName = Componenty[name];  
    ReactDOM.render(<TagName token = {this.state.token} username = {this.state.username} id = {this.state.id} rok={this.state.rok} nazwastrony={this.state.nazwastrony}/>, document.getElementById("root"));
  }

render() {
const { rok, lata } = this.state;
return (
<React.Fragment>
<div class="container-fluid">
      <h1>Zmień rok</h1>
      <hr />
      <div class="row ">  

      <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3">  

      <div class="input-group mb-3">
      <select class="custom-select" id="inputGroupSelect01" onChange={this.onChange.bind(this)} name="rok" value={rok}>
      {lata.map((rok, index) => {
      return (
      <option key={index} value={rok}>{rok}</option>
      );
      })}
      </select>
      <div class="input-group-append">
      <button class="btn btn-outline-secondary" type="button" onClick={this.zmianaroku.bind(this)}>Zmień rok</button>
      </div>
      </div>

      </div>
      </div>      
</div>




</React.Fragment>
    );
  }
}

