import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import swal from 'sweetalert';
import Logowanie from './Log';
import Wydzialy from './Wydzialy';
import Rok from './Rok';
import Lista from './Lista';
import App from './App';
import Dodaj_plan from './Dodaj_plan';
import Lista_zestawienia from './Lista_zestawienia';
import apiurl from './apiurl';


export default class ChangePswd extends React.Component {
    constructor(props) {
      super(props);    

      this.state = {
      errors: null, 
      kodoper: sessionStorage.getItem('koD_OPER'),
      token: props.token,
      username: props.username,
      id: props.id,
      rok: props.rok,
      old:'',
      new:'',
      confirm: '',
      nazwastrony: props.nazwastrony
      };
    }

    onChange = (e) => {
      this.setState({  [e.target.name]: e.target.value });
    }

    ChangePswd = (z) => {
      z.preventDefault();
      axios.put(apiurl+"api/Auth/ChangePwd",
          {koD_OPER : this.state.kodoper , oldPassword : this.state.old, newPassword : this.state.new, confirmPassword : this.state.confirm },
          {headers : {'Authorization' : this.state.token } })
          .then(res => {
          swal("Zmieniono hasło!");
          localStorage.clear();
          sessionStorage.clear();
          ReactDOM.render(<Logowanie />, document.getElementById("root"));
          })
      .catch(error => swal(error.response.data.message));
    }
    
    wroc = () => {
      const Componenty = {
        "Lista": Lista,
        "App": Lista,
        "Dodaj_plan": Dodaj_plan,
        "Wydzialy": Wydzialy,
        "Edytuj_wydzial": Wydzialy,
        "Dodaj_wydzial": Wydzialy,
        "Lista_zestawienia": Lista_zestawienia,
        "Gen_zestawienie": Lista_zestawienia,
        "Pokaz_zestawienie": Lista_zestawienia,
        "Rok": Lista,
      }
      let name = this.state.nazwastrony;
      let TagName = Componenty[name];  
      ReactDOM.render(<TagName apiurl={this.props.apiurl} token = {this.state.token} username = {this.state.username} id = {this.state.id} rok={this.state.rok}/>, document.getElementById("root"));
    }


  render() {
  return (
  <React.Fragment>
  <div class="container-fluid">
      <h1>Zmień hasło</h1>
      <hr />

      <form id="function" name="form" class="form-horizontal" onSubmit={(z) => this.ChangePswd(z)}> 
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Użytkownik:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3"><label class="control-label">{this.state.username}</label></div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label class="control-label">Stare hasło:</label></div>
                 <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3"><input type="password" class="form-control" id="old" name="old" placeholder="Wpisz stare hasło" onChange={this.onChange.bind(this)}></input></div>
          </div><br></br>
          <div class="row">
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Nowe hasło:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3"><input type="password" class="form-control" id="new" name="new" placeholder="Wpisz nowe hasło" onChange={this.onChange.bind(this)}></input></div>
          </div><br></br>
          <div class="row">
                 <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"><label >Powtórz nowe hasło:</label></div>
                  <div class="col-xs-5 col-sm-5 col-md-3 col-lg-3"><input type="password" class="form-control" id="confirm" name="confirm" placeholder="Powtórz nowe hasło" onChange={this.onChange.bind(this)}></input></div>
          </div><br></br>
          <div class="row">
                <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2"></div>
                  <div class="col-xs-4 col-sm-4 col-md-2 col-lg-2">
                  <button  class="btn btn-light pull-left" onClick={() => this.wroc()}>Anuluj</button>
                  </div>
                  <div class="col-xs-3 col-sm-3 col-md-2 col-lg-2">
                  <button type="submit"  class="btn btn-light pull-right" id="function">Zmień hasło</button>
                  </div>
        </div>
      </form>
  </div>
  </React.Fragment>
      );
    }
  }
