import React from 'react';
import ReactDOM from 'react-dom';
import top from './top.png';
import './index.css';
import './Header.css';

import ChangePswd from './ChangePswd';
import Wydzialy from './Wydzialy';
import Lista from './Lista';
import Lista_zestawienia from './Lista_zestawienia';
import Logowanie from './Log';
import axios from 'axios';
import swal from 'sweetalert';
import Rok from './Rok';
import Info from './Info';
import apiurl from './apiurl';
import Header from './Header';


export default class NavBar extends React.Component {
    constructor(props) {
    super(props);    
      this.state = {
      token: props.token,
      username: props.username,
      id: props.id,
      nazwastrony: props.nazwastrony,
      kodoper: props.kodoper,
      Header1: true,
      Header2: false,
      cleantimer: 0,
      rok: props.rok,
      active: 'Lista'
      };
    }
    
  componentDidMount() {    
    ReactDOM.render(<Header token={this.state.token}/>, document.getElementById("nav"));
  }

  active(name) {
    if(name === 'Lista' || name === 'Wydzialy' || name === 'Gen_zestawienie'){
    this.setState({active : name})
    }

  }

  componentWillReceiveProps(nextProps) {
    this.setState({ token: nextProps.token, username: nextProps.username, id: nextProps.id, kodoper: nextProps.kodoper, rok: nextProps.rok, nazwastrony: nextProps.nazwastrony})  
    ReactDOM.render(<Header token={this.state.token}/>, document.getElementById("nav"));
    this.active(nextProps);
  }

  counterclick() {
    this.setState(({ cleantimer }) => ({ cleantimer: cleantimer + 1 }));
  }

  przejdz = (name) => {
    const Componenty = {
      "ChangePswd": ChangePswd,
      "Wydzialy": Wydzialy,
      "Rok": Rok,
      "Lista": Lista,
      "Gen_zestawienie": Lista_zestawienia,
      "Info": Info,
    }
    let TagName = Componenty[name];  
    if(this.state.token === 'brak'){
    swal("Musisz sie zalogować !", {
      closeOnClickOutside: false,
      buttons: {
        do: {
          text: "OK",
          value: "do",
        },
      },
    })
    .then((value) => {
      switch (value) {
        case "do":
          ReactDOM.render(<Logowanie />, document.getElementById("root"));
          break;
      }
    });
    }
    else{
    ReactDOM.render(<TagName  token = {this.state.token} username = {this.state.username} id = {this.state.id} rok={this.state.rok} kodoper={this.state.kodoper} nazwastrony={this.state.nazwastrony}/>, document.getElementById("root"));
    this.active(name);
    }
  }

  logout = () => {
    axios.post(apiurl+"api/Auth/logout", null , 
        {headers : { 'Authorization' : this.state.token } })
        .then(() => {
          ReactDOM.render(<Logowanie />, document.getElementById("root"));
        })
    .catch(() => swal("Nie wylogowano!"));
  }


  render() {
  const { rok, username, active } = this.state
  const Lista = active === "Lista" ? ("active") : ("");
  const Wydzialy = active === "Wydzialy" ? ("active") : ("");
  const Gen = active === "Gen_zestawienie" ? ("active") : ("");
  const Rok = active === "Rok" ? ("active") : ("");
  const ChangePswd = active === "ChangePswd" ? ("active") : ("");
  return (
  <React.Fragment>
  <aside id="main-sidebar" className="sidenav">
  <section className="sidebar" >
  <ul className="sidebar-menu">
    <li className="treeview" >           
    <a className={Lista} href="#"  onClick={() => this.przejdz('Lista')}><i className="center fas fa-coins"></i> <span className="napis"> Budżet</span></a>
    </li>
    <li className="treeview" >           
    <a className={Wydzialy} href="#"  onClick={() => this.przejdz('Wydzialy')}><i className="center fas fa-id-card"></i> <span className="napis"> Wydziały</span></a>
    </li>
    <li className="treeview" >           
    <a className={Gen} href="#"  onClick={() => this.przejdz('Gen_zestawienie')}><i className="center fas fa-print"></i> <span className="napis"> Zestawienia</span></a>
    </li>
    <li className="treeview" >           
      <a className={Rok} href="#"  onClick={() => this.przejdz('Rok')}><i className="center far fa-calendar"></i> { rok ? (<span className="napis">Zmień rok ({rok})</span>) : (<span className="napis">Zmień rok</span>)}</a>
    </li>
    <li className="treeview" >           
      <a className={ChangePswd} href="#" onClick={() => this.przejdz('ChangePswd')}><i className="center fas fa-key"></i> <span className="napis"> Zmień hasło</span></a>
    </li>

  </ul>
  </section>
  </aside >
  
  </React.Fragment>
  );
  }
  }
